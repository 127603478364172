import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { NabuApiService } from '@services/api/nabu-api.service';
import { Location, LocationStrategy } from '@angular/common';
import { Router, ActivatedRoute  } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env/environment';
import { Storage } from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class DataSourceService {

  public currentActiveProject: any = { id: 0 };

  public isMenuFolded = false;

  public showCoaching = true;

  private queryParams;
  private pageLoader = [];
  public tagList;
  public areaList;
  public pagesData;

  public isCoaching = null; // 19 to rm
  public coachingData: any = {};

  env = environment;

  constructor(
    public api: NabuApiService,
    public location: Location,
    public router: Router,
    public route: ActivatedRoute,
    public loadingController: LoadingController,
    public locationStrategy: LocationStrategy,
    public http: HttpClient,
    public storage: Storage,
  ) {
    // this.restoreFilterFromUrl();

    this.route.queryParams
    .subscribe(params => {
      // Copy, not reference
      this.queryParams = JSON.parse(JSON.stringify(params)) || {};
      console.log('queryParams', params); // {order: "popular"}
    });
    this.loadMeta();

  }

  public async loadMeta() {
    const lastActiveProject = await this.storage.get('lastActiveProject');
    if ( lastActiveProject ) {
      this.currentActiveProject = lastActiveProject;
    }
  }

  public setCoachingTag(data) {
    this.coachingData = {
      ...this.coachingData,
      ...data
    }
  }

  public async setCurrentActiveProject(project, load = false) {
    await this.storage.set('lastActiveProject', project);
    this.coachingData.projectId = project.id;
    this.currentActiveProject = project;

    if( load ) {
      this.router.navigate(['/app/dashboard/' + project.id], { replaceUrl: false });
    }
  }

  public getQuerymap() {
    const qm = this.queryParams;
    console.log(qm);
    return qm;
  }

  public getServerMockCall(data = {}): Observable<any> {
    const observable = this.api.get('servicetools/response/code/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public getProfile(): Observable<any> {
    const observable = this.api.get('api/v1/profile/', {}, {}, {preloader: false});
    return observable;
  }

  // Endpoints - Campaing
  public createCampaing(): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/create-campaign/', {}, {}, {preloader: true});
    return observable;
  }

  public editCampaing(id, data): Observable<any> {
    const observable = this.api.put('api/v1/growthworks/campaign/' + id + '/update/', data, {}, {preloader: true});
    return observable;
  }

  public cloneCampaing(data): Observable<any> {
    const observable = this.api.post('api/v1/growthworks/campaign/clone/', data, {}, {preloader: true});
    return observable;
  }  

  public deleteCampaing(id): Observable<any> {
    const observable = this.api.delete('api/v1/growthworks/campaign/' + id + '/delete/', {}, {}, {preloader: true});
    return observable;
  }

  public listCampaing(preloader = false): Observable<any> {
    let observable;
    if ( this.isCoaching ) {
      observable = this.api.get('api/v1/growthworks/client/' + this.isCoaching + '/campaigns-list/', {}, {}, {preloader});
    } else {
      observable = this.api.get('api/v1/growthworks/campaigns-list/', {}, {}, {preloader});
    }
    return observable;
  }

  public viewCampaing(campingId, filters, preloader = false): Observable<any> {
    let observable;
    if ( this.isCoaching ) {
      observable = this.api.get('api/v1/growthworks/client/campaign/' + campingId + '/detail/', filters, {}, {preloader});
    } else {
      observable = this.api.get('api/v1/growthworks/campaign/' + campingId + '/detail/', filters, {}, {preloader});
    }
    return observable;
  }

  // Endpoints - Assets
  public createScript(campingId, templateId): Observable<any> {
    const observable = this.api.post('api/v1/growthworks/campaign/' + campingId + '/add-asset/', {template: templateId}, {}, {preloader: false});
    return observable;
  }
  public createScriptPack(campingId, templateId): Observable<any> {
    const observable = this.api.post('api/v1/growthworks/campaign/' + campingId + '/add-assets-pack/', {template: templateId}, {}, {preloader: false});
    return observable;
  }

  public createAiScript(campingId, templateId): Observable<any> {
    const observable = this.api.post('api/v2/content-generation/campaign/' + campingId + '/template/' + templateId + '/add-asset/', {}, {}, {preloader: false});
    return observable;
  }
  public createAiScriptPack(campingId, templateId): Observable<any> {
    const observable = this.api.post('api/v2/content-generation/campaign/' + campingId + '/template/' + templateId + '/add-assets-pack/', {}, {}, {preloader: false});
    return observable;
  }
  public getCreateAiScript(campingId, templateId): Observable<any> {
    const observable = this.api.get('api/v2/content-generation/campaign/' + campingId + '/template/' + templateId + '/add-asset/', {}, {}, {preloader: false});
    return observable;
  }
  public getCreateAiScriptPack(campingId, templateId): Observable<any> {
    const observable = this.api.get('api/v2/content-generation/campaign/' + campingId + '/template/' + templateId + '/add-assets-pack/', {}, {}, {preloader: false});
    return observable;
  }

  public deleteScript(campingId, assetId): Observable<any> {
    const observable = this.api.delete('api/v1/growthworks/campaign/' + campingId + '/asset/' + assetId + '/delete/', {}, {}, {preloader: true});
    return observable;
  }

  public cloneScript(campingId, assetId): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/campaign/' + campingId + '/asset/' + assetId + '/clone/', {}, {}, {preloader: true});
    return observable;
  }

  public cloneScriptPack(campingId, assetId): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/campaign/' + campingId + '/asset/' + assetId + '/clone-pack/', {}, {}, {preloader: true});
    return observable;
  }

/*
  public publishScript(campingId, assetId): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/campaign/' + campingId + '/asset/' + assetId + '/clone/', {}, {}, {preloader: true});
    return observable;
  }
  */

  public editScript(campingId, assetId, data): Observable<any> {
    const observable = this.api.put('api/v1/growthworks/campaign/' + campingId + '/asset/' + assetId + '/update/', data, {}, {preloader: false});
    return observable;
  }

  // api/v1/growthworks/campaign/2/assets-filters/
  public assetsFilters(campingId): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/campaign/' + campingId + '/assets-filters/', {}, {}, {preloader: false});
    return observable;
  }

  public viewScript(assetId, preloader = false, data = {}): Observable<any> {
    let observable;
    if ( this.isCoaching ) {
      observable = this.api.get('api/v1/growthworks/client/asset/' + assetId + '/detail/', data, {}, {preloader});
    } else {
      observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/detail/', data, {}, {preloader});
    }
    return observable;
  }

  public viewSharedScript(slugId, preloader = false, data = {}): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/asset/' + slugId + '/detail/share/', data, {}, {preloader});
    return observable;
  }

  // http://127.0.0.1:8000/api/v1/growthworks/staff/preview-stage/3/
  public viewScriptPreview(assetId): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/staff/preview-stage/' + assetId + '/', {}, {}, {preloader: false});
    return observable;
  }
  public viewTemplatePreview(assetId): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/staff/template/' + assetId + '/detail/', {}, {}, {preloader: false});
    return observable;
  }

  // viewTemplatePreview

  public updateScriptStep(assetId, stageId, data): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.put('api/v1/growthworks/asset/' + assetId + '/stage-update/' + stageId + '/', data, {}, {preloader: false, messageSuccess: 'Changes has been successfully saved.'});
    return observable;
  }
  /*
  http://127.0.0.1:8000/api/v1/growthworks/asset/72/stage/43/download-picture/ - отдаю картинку jpeg
  http://127.0.0.1:8000/api/v1/growthworks/asset/72/stage/43/download-pdf/ - загружается pdf
  http://127.0.0.1:8000/api/v1/growthworks/asset/72/download-pdf/ - загрузить пдф с картинками всех стейджев, которые отмечены progress=100
  */
  public getScriptStepPicture(assetId, stageId, data): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/stage/' + stageId + '/download-picture/', data, {}, {preloader: false});
    return observable;
  }
  public getScriptStepPdf(assetId, stageId, data): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/stage/' + stageId + '/download-pdf/', data, {}, {preloader: false});
    return observable;
  }
  public getScriptStepsPdf(assetId): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/download-pdf/', {}, {}, {preloader: true});
    return observable;
  }
  public getScriptStepsZip(assetId): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/download-zip/', {}, {}, {preloader: true});
    return observable;
  }
  public getScriptStepsTxt(assetId): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v2/growthworks/asset/' + assetId + '/download-txt/', {}, {responseType: 'text'}, {preloader: true});
    return observable;
  }
  public getSlidesStepsGoogleSlides(assetId): Observable<any> {
    // https://dev.api.growthworks.io/api/v1/growthworks/asset/36061/download-gs/
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/download-gs/', {}, {}, {preloader: true});
    return observable;
  }
  public getScriptStepsPdfPack(assetId): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/download-pdf-pack/', {}, {}, {preloader: true});
    return observable;
  }
  public getScriptStepsZipPack(assetId): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/download-zip-pack/', {}, {}, {preloader: true});
    return observable;
  }
  public getScriptStepsTxtPack(assetId): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v2/growthworks/asset/' + assetId + '/download-txt-pack/', {}, {}, {preloader: true});
    return observable;
  }

  /*
  http://127.0.0.1:8000/api/v1/growthworks/asset/181/download-zip-pack/
  http://127.0.0.1:8000/api/v1/growthworks/asset/181/download-pdf-pack/
  */

  public getGWOnboarding(): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v1/growthworks/onboarding/', {}, {}, {preloader: true});
    return observable;
  }
  // http://127.0.0.1:8000/api/v1/growthworks/onboarding-sections/1/update/
  public putGWOnboarding(data): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.post('api/v1/growthworks/client/onboarding/', data, {}, {preloader: false});
    return observable;
  }

  public getOnboarding(): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.get('api/v1/growthworks/onboarding-sections/', {}, {}, {preloader: false});
    return observable;
  }
  // http://127.0.0.1:8000/api/v1/growthworks/onboarding-sections/1/update/
  public putOnboarding(index, data): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const observable = this.api.put('api/v1/growthworks/onboarding-sections/' + index + '/update/', data, {}, {preloader: false});
    return observable;
  }
  //
  public getPlanVariables(planId): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    let observable;
    if ( this.isCoaching ) {
      observable = this.api.get('api/v1/growthworks/client/plan/' + planId + '/detail/', {}, {}, {preloader: false});
    } else {
      observable = this.api.get('api/v1/growthworks/plan/' + planId + '/update/', {}, {}, {preloader: false});
    }
    return observable;
  }
  public setPlanVariables(planId, key, value): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const data = {};
    (data as any)[key] = value;
    const observable = this.api.put('api/v1/growthworks/plan/' + planId + '/update/', { variable: data }, {}, {preloader: false});
    return observable;
  }
  public setPlanVariablesCampaign(planId, key, value, campaingId): Observable<any> {
    // api/v1/growthworks/asset/{asset_pk}/stage-update/{temp_stage_pk}
    const data = {};
    // data.campaign = campaingId;
    (data as any)[key] = value;
    const observable = this.api.put('api/v1/growthworks/plan/' + planId + '/update/', { variable: data, campaign: campaingId }, {}, {preloader: false});
    return observable;
  }

  // api/v1/growthworks/templates/list
  public listTemplates(tagId): Observable<any> {
    let data = {};
    if ( tagId ) {
      data = {tags: tagId};
    }
    const observable = this.api.get('api/v1/growthworks/templates/list/', data, {}, {preloader: true});
    return observable;
  }

  public getTemplateSettingsById(templateId, contentType) {
    // api/v1/growthworks/templates/18/presets/base/
    const observable = this.api.get('api/v1/growthworks/templates/' + templateId + '/presets/' + contentType + '/', {}, {}, {preloader: false});
    return observable;
  }

  public viewTemplate(templateId): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/templates/' + templateId + '/detail/', {}, {}, {preloader: false});
    return observable;
  }

  public viewTemplateVariations(templateId): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/templates/' + templateId + '/', {}, {}, {preloader: false});
    return observable;
  }

  // api/v1/growthworks/tags/list
  public listTags(): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/tags/list/', {}, {}, {preloader: false});
    return observable;
  }

  public bindTemplate(assetId, templateId): Observable<any> {
    const data = {
      asset: assetId,
      template: templateId,
    };
    const observable = this.api.post('api/v1/growthworks/asset/' + assetId + '/choose-template/', data, {}, {preloader: true});
    return observable;
  }

  getUserProfile(preloader = false): Observable<any> { // API
    const observable = this.api.get('api/v1/profile/', {}, {}, {preloader});
    return observable;
  }

  getUserCountryList(): Observable<any> {
    const observable = this.api.get('api/v1/profile/countries-list/', {}, {}, {preloader: false});
    return observable;
  }

  getUserTimezones(): Observable<any> {
    const observable = this.http.get('./assets/tz.json', {});
    return observable;
  }

  updateUserProfile(profile): Observable<any> { // API
    const observable = this.api.put('api/v1/profile/update/', profile, {}, {preloader: true});
    return observable;
  }

  updateUserProfileAvatar(file): Observable<any> { // API
    const observable = this.api.put('api/v1/profile/update/', file, {}, {preloader: true});
    return observable;
  }

  getBrandData(): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/brands/', {}, {}, {preloader: false});
    return observable;
  }

  putBrandData(data): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/brands/', data, {}, {preloader: false});
    return observable;
  }

  postBrandImageData(data): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/brands/upload-logo/', data, {}, {preloader: true});
    return observable;
  }

  postMediaImageData(data, preloader = true): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/brands/upload-media/', data, {}, {preloader});
    return observable;
  }

  postColorData(data): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/brands/upload-color/', data, {}, {preloader: false});
    return observable;
  }

  postFontData(data): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/brands/upload-typography/', data, {}, {preloader: false});
    return observable;
  }

  postHeadingsData(data): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/brands/upload-titles/', data, {}, {preloader: false});
    return observable;
  }

  // delete DELETE http://127.0.0.1:8000/api/v1/growthworks/brands/upload-color/7/delete/ только для OTHER
  deleteColorData(id): Observable<any> { // API
    const observable = this.api.delete('api/v1/growthworks/brands/upload-color/' + id + '/delete/', {}, {}, {preloader: true});
    return observable;
  }

  deleteMediaData(id): Observable<any> { // API
    const observable = this.api.delete('api/v1/growthworks/brands/upload-media/' + id + '/delete/', {}, {}, {preloader: true});
    return observable;
  }

  deleteLogoData(id): Observable<any> { // API
    const observable = this.api.delete('api/v1/growthworks/brands/upload-logo/' + id + '/delete/', {}, {}, {preloader: true});
    return observable;
  }
  // http://127.0.0.1:8000/api/v1/growthworks/staff/templates/list/
  public listTemplatesManage(tagId): Observable<any> {
    let data = {};
    if ( tagId ) {
      data = {tags: tagId};
    }
    const observable = this.api.get('api/v1/growthworks/staff/templates/list/', data, {}, {preloader: true});
    return observable;
  }
  // api/v1/growthworks/staff/template/1/detail/
  public getTemplateDataManage(id): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/staff/template/' + id + '/detail/', {}, {}, {preloader: true});
    return observable;
  }

  public getTemplateVariablesManage(): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/variables/', {}, {}, {preloader: false});
    return observable;
  }

  public putTeplateStageManage(id, data): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/staff/template/update-stage/' + id + '/', data, {}, {preloader: false});
    return observable;
  }

  // api/v1/growthworks/staff/template/create/
  public postTeplateCreateManage(data): Observable<any> { // API
    const observable = this.api.post('api/v1/growthworks/staff/template/create/', data, {}, {preloader: true});
    return observable;
  }

  public putTeplateEditManage(id, data): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/staff/template/' + id + '/update/', data, {}, {preloader: true});
    return observable;
  }
  // api/v1/growthworks/staff/template/stage/1/delete/

  public deleteTeplateStageManage(id): Observable<any> { // API
    const observable = this.api.delete('api/v1/growthworks/staff/template/stage/' + id + '/delete/', {}, {}, {preloader: true});
    return observable;
  }

  public postTeplateStageCreateManage(id, data): Observable<any> { // API
    const observable = this.api.post('api/v1/growthworks/staff/template/' + id + '/add-stage/', data, {}, {preloader: true});
    return observable;
  }

  public putTeplateStageEditManage(id, data): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/staff/template/update-stage/' + id + '/', data, {}, {preloader: true});
    return observable;
  }

  // Coach center

  public listCoachContacts(data): Observable<any> { // API
    const observable = this.api.get('api/v1/coachcenter/users-list/', data, {}, {preloader: true});
    return observable;
  }

  public listConversations(data): Observable<any> { // API
    const observable = this.api.get('api/v1/coachcenter/chats/list/', data, {}, {preloader: false});
    return observable;
  }
  // http://127.0.0.1:8000/api/v1/coachcenter/start-chat/2/
  public postConversationsStartChat(id): Observable<any> { // API
    const observable = this.api.get('api/v1/coachcenter/start-chat/' + id + '/', {}, {}, {preloader: true});
    return observable;
  }

  public getDiscussionData(id): Observable<any> { // API
    const observable = this.api.get('api/v1/coachcenter/chats/' + id + '/details/', {limit: 10, page: 1}, {}, {preloader: false});
    return observable;
  }

  public getDiscussionDataLimit(id, data): Observable<any> { // API
    const observable = this.api.get('api/v1/coachcenter/chats/' + id + '/details/', data, {}, {preloader: false});
    return observable;
  }

  public postDiscussionMessageData(id, data): Observable<any> { // API
    const observable = this.api.post('api/v1/coachcenter/chats/' + id + '/create-message/', data, {}, {preloader: false});
    return observable;
  }

  public getMessagesCoachPreview(client, project): Observable<any> { // API
    const observable = this.api.get('api/v1/coachcenter/client/' + client + '/campaign-chat/' + project + '/', {}, {}, {preloader: false});
    return observable;
  }

  public putMessagesCoachPreview(chat, data): Observable<any> { // API
    const observable = this.api.post('api/v1/coachcenter/chats/' + chat + '/create-message/', data, {}, {preloader: false});
    return observable;
  }

  public postConversationsStartChatPerClient(client, campaign): Observable<any> { // API
    const observable = this.api.get('api/v1/coachcenter/start-chat/' + client + '/' + campaign + '/', {}, {}, {preloader: false});
    return observable;
  }

  // End Coach Center

  // POST https://apifletcher.iteksas.com/api/v1/growthworks/campaign/48/roadmap/add-asset/,  где 48 - id компании
  public postTemplatesCreatePdf(campaign, data, theme = 'roadmap'): Observable<any> { // API
    const observable = this.api.post('api/v1/growthworks/campaign/' + campaign + '/' + theme + '/add-asset/', { data: data }, {}, {preloader: false});
    return observable;
  }

  public postTemplatesCreatePdfPack(campaign, data, theme = 'roadmap'): Observable<any> { // API
    const observable = this.api.post('api/v1/growthworks/campaign/' + campaign + '/' + theme + '/add-assets-pack/', { data: data }, {}, {preloader: false});
    return observable;
  }

  public getIconsForPreview(kw, page, limit): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/search-icons/' + kw + '/', {page, limit}, {}, {preloader: false});
    return observable;
  }

  public getUnsplashForPreview(kw, page, limit): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/search-picture/' + kw + '/', {page, limit}, {}, {preloader: false});
    return observable;
  }

  public getAssetsForPreview(id): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/campaign/' + id + '/roadmaps/', {}, {}, {preloader: true}); // change of 27.02
    return observable;
  }

  public postUnsplashDownloadData(data): Observable<any> { // API
    const observable = this.api.post('api/v1/growthworks/download-picture/', data, {}, {preloader: false});
    return observable;
  }

  public getCloneStage(assetId, stepId, data = {}): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/clone-stage/' + stepId + '/', data, {}, {preloader: false});
    return observable;
  }
  /*
  добавление стейджа:  POST: http://127.0.0.1:8000/api/v1/growthworks/asset/350/add-stage/?next_stage=1067&prev_stage=1068
  next_stage=1067 - как и в клоне, prev_stage=1068 - после которого ставим. Если первый/последний - одного параметра может не быть. Если нет ни одного параметра - будет создан с order=1
  ожидает поле body, нужно отправить хотя бы пустой body={}
  */
  public postAddStage(assetId, data = {}): Observable<any> { // API
    const observable = this.api.post('api/v1/growthworks/asset/' + assetId + '/add-stage/', data, {}, {preloader: false});
    return observable;
  }

  //удаление стейджа: DELETE: http://127.0.0.1:8000/api/v1/growthworks/asset/350/delete-stage/1049/
  public deleteStage(assetId, stepId): Observable<any> { // API
    const observable = this.api.delete('api/v1/growthworks/asset/' + assetId + '/delete-stage/' + stepId + '/', {}, {}, {preloader: false});
    return observable;
  }

  //api/v1/growthworks/asset/350/move-stage/1069/?next_stage=1067&prev_stage=1068
  public postMoveStage(assetId, stepId, data): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/move-stage/' + stepId + '/', data, {}, {preloader: false});
    return observable;
  }

  // http://127.0.0.1:8000/api/v1/growthworks/asset/192/apply-to/
  // POST key_data = {...element}

  public postApplyAllData(id, data): Observable<any> { // API
    const observable = this.api.post('api/v1/growthworks/asset/' + id + '/apply-to/', data, {}, {preloader: true});
    return observable;
  }

  //api/v1/growthworks/asset/2200/stage/10360/thumbnail/
  public getThumbnailStatus(id, stageId): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/asset/' + id + '/stage/' + stageId + '/thumbnail/', {}, {}, {preloader: false});
    return observable;
  }

  public putRecentColors(color): Observable<any> { // API
    const observable = this.api.put('api/v1/growthworks/brands/recent-colors/', {color}, {}, {preloader: false});
    return observable;
  }

  public getRecentColors(): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/brands/recent-colors/', {}, {}, {preloader: false});
    return observable;
  }

  public getRecentMedia(data): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/brands/recent-media/', data, {}, {preloader: false});
    return observable;
  }
  // http://127.0.0.1:8000/api/v1/growthworks/brands/upload-recent-media/
  public postRecentMediaImageData(data, stageId, preloader = true): Observable<any> { // API
    const observable = this.api.post('api/v1/growthworks/brands/upload-recent-media/' + ((stageId) ? '?stage_id='+stageId : ''), data, {}, {preloader});
    return observable;
  }
  // DELETE /api/v1/growthworks/brands/recent-media/24/delete/
  public deleteRecentMediaImage(id, preloader = true): Observable<any> { // API
    const observable = this.api.delete('api/v1/growthworks/brands/recent-media/' + id + '/delete/', {}, {}, {preloader});
    return observable;
  }

  public getSidemenuProgress(projectId, preloader = true): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/campaign/' + projectId + '/progress/', {}, {}, {preloader});
    return observable;
  }

  // http://127.0.0.1:8000/api/v1/growthworks/campaign/8/mark-progress/SLUG/
  public postSidemenuProgress(projectId, slug): Observable<any> { // API
    const observable = this.api.get('api/v1/growthworks/campaign/' + projectId + '/mark-progress/' + slug + '/', {}, {}, {preloader: true});
    return observable;
  }

  /*
  PUT /api/v1/growthworks/brands/recent-colors/
  {"color": hex}

  GET /api/v1/growthworks/brands/recent-colors/
  [
      "999999",
      "888888",
      "777777",
      "666666",
      "555555",
      "444444"
  ]
  */

  /*
  public a() {
    const observable = this.api.post('api/v1/growthworks/asset/' + id + '/apply-to/', {}, {}, {preloader: true});
    return observable;
  }*/ // api/v1/growthworks/asset/349/apply-to/

  // http://127.0.0.1:8000/api/v1/growthworks/campaign/2/roadmaps/


  // V2 Section
  // Endpoints - Campaing
  public createCampaingV2(): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/create-campaign/', {}, {}, {preloader: true});
    return observable;
  }

  public getPlanDataV2(id): Observable<any> {
    // /api/v2/growthworks/plan/16/update/
    let observable;
    if ( this.isCoaching ) {
      observable = this.api.get('api/v1/growthworks/client/plan/' + id + '/detail/', {}, {}, {preloader: false});
    } else {
      observable = this.api.get('api/v2/growthworks/plan/' + id + '/update/', {}, {}, {preloader: false});
    }
    return observable;

  }

  public putPlanDataV2(id, data = {}): Observable<any> {
    const observable = this.api.put('api/v2/growthworks/plan/' + id + '/update/', data, {}, {preloader: true});
    return observable;
  }

  public putRoadmapDataV2(id, order, data={}): Observable<any> {
    // http://127.0.0.1:8000/api/v2/growthworks/plan/29/stage/0/update/
    const observable = this.api.put('api/v2/growthworks/plan/' + id + '/stage/' + order + '/update/', data, {}, {preloader: true});
    return observable;
  }

  public getRecreateAsset(projectId, assetId): Observable<any> {
    // /api/v2/growthworks/campaign/128/asset/2714/recreate/
    const observable = this.api.get('api/v2/growthworks/campaign/' + projectId + '/asset/' + assetId + '/recreate/', {}, {}, {preloader: true});
    return observable;
  }

  public getRecreateAssetThemed(projectId, assetId, themeList): Observable<any> {
    // /api/v2/growthworks/campaign/41/asset/1707/lmb/recreate/
    const observable = this.api.get('api/v2/growthworks/campaign/' + projectId + '/asset/' + assetId + '/' + themeList + '/recreate/', {}, {}, {preloader: true});
    return observable;
  }

  public postAssetChangeTheme(projectId, assetId, themeList, data): Observable<any> {
    // /api/v2/growthworks/campaign/41/asset/1707/lmb/recreate/
    const observable = this.api.post('api/v2/growthworks/campaign/' + projectId + '/asset/' + assetId + '/' + themeList + '/change-theme/', {data : data}, {}, {preloader: true});
    return observable;
  }

  // https://apifletcher.iteksas.com/api/v2/growthworks/campaign/41/asset/1856/lmb/recreate-assets-pack/
  public getRecreateAssetThemedPack(projectId, assetId, themeList): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/campaign/' + projectId + '/asset/' + assetId + '/' + themeList + '/recreate-assets-pack/', {}, {}, {preloader: true});
    return observable;
  }

  // env.apiUrl + '/api/v2/growthworks/asset/' + assetId + '/download-pdf/'
  public getAssetPfd(assetId): Observable<any> {
    console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/growthworks/asset/' + assetId + '/download-pdf/', {}, {responseType: 'blob'}, {preloader: true});
    return observable;
  }

  public getPlanPfd(planId): Observable<any> {
    console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/growthworks/plan/' + planId + '/download-pdf/', {}, {responseType: 'blob'}, {preloader: true});
    return observable;
  }

  public getGraphicsList(): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('graphics/', {}, {}, {preloader: false});
    return observable;
  }

  public postAssetRename(assetId, title): Observable<any> {
    /*
    POST http://127.0.0.1:8000/api/v2/growthworks/asset/327/rename/
    “title”: “new title”
    1:30 якщо 327 це id окремого asset - міняю тайтл йому, якщо головний есет в паку - то паку
    */
    // console.log('getAssetPfd', this.api);
    const observable = this.api.post('api/v2/growthworks/asset/' + assetId + '/rename/', { title }, {}, {preloader: true});
    return observable;
  }
  // http://127.0.0.1:8000/api/v2/growthworks/plan/54/download-pdf/

  public getFunnelsList(campaignId, preloader = false): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnels-list/', {}, {}, {preloader});
    return observable;
  }

  public getFunnelThemesList(funnelSlug): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/funnels/' + funnelSlug + '/themes/', {}, {}, {preloader: false})
    .pipe(
      map((res: any) => res.sort( (a, b) => a.order - b.order))
    )
    return observable;
  }

  // api/v2/funnels/campaign/<campaign_pk>/funnel/<funnel_id>/leads/
  public getFunnelLeadsList(campaignId, funnelId, q, offset): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/leads/', {q, offset, limit: 10}, {}, {preloader: false});
    return observable;
  }

  public getFunnelStatsList(campaignId, funnelId, data = {}): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/statistic/', {...data}, {}, {preloader: false});
    return observable;
  }

  // https://apifletcher.iteksas.com/api/v2/funnels/campaign/193/to-add/
  public getFunnelOriginList(campaignId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/to-add/', {}, {}, {preloader: false});
    return observable;
  }

  // GET/POST api/v2/funnels/campaign/<campaign_pk>/funnel/<funnel_id>/set-subdomain/
  public getFunnelSubdomain(campaignId, funnelId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/set-subdomain/', {}, {}, {preloader: false});
    return observable;
  }

  public postFunnelSubdomain(campaignId, funnelId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/set-subdomain/', {}, {}, {preloader: true});
    return observable;
  }

  // /api/v2/funnels/campaign/<pk>/funnel/<int:funnel_id>/set-asset/
  public putFunnelAssetLink(campaignId, funnelId, data): Observable<any> {
    const observable = this.api.put('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/set-asset/', {...data}, {}, {preloader: false, backendError: true});
    return observable;
  }

  // /api/v2/funnels/campaign/284/funnel/opt_in_1/start/
  // /api/v2/funnels/campaign/<pk>/funnel/<funnel_slug>/themes/<theme_slug>/<var_slug>/start/
  public createFunnel(campaignId, funnelSlug, themeSlug, varSlug, hot_step = null): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelSlug + '/themes/' + themeSlug + '/' + varSlug +'/start/', {hot_step}, {}, {preloader: false});
    return observable;
  }

  public createFunnelWithQuery(campaignId, funnelSlug, themeSlug, varSlug, data: any = {}): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelSlug + '/themes/' + themeSlug + '/' + varSlug +'/start/', {...data}, {}, {preloader: false});
    return observable;
  }

  // /api/v2/funnels/campaign/284/funnel/4/detail/
  public getFunnelDetails(campaignId, funnelId): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/detail/', {}, {}, {preloader: false, noInternet: false});
    return observable;
  }

  // Additional Features
  public updateFunnelDetails(campaignId, funnelId, data): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.put('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/update/', {...data}, {}, {preloader: true});
    return observable;
  }

  public deleteFunnel(campaignId, funnelId): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/delete/', {}, {}, {preloader: true});
    return observable;
  }

  // Landing Blocks / group=content
  public getFunnelLandingBlocks(group = null, subgroup = null): Observable<any> {
    
    const searchQuery: any = {};
    if ( group !== null ) {
      searchQuery.group = group;
    }
    if ( subgroup !== null ) {
      searchQuery.subgroup = subgroup;
    }
    console.log('getAssetPfd', searchQuery);
    const observable = this.api.get('api/v2/funnels/blocks/to-add/', searchQuery, {}, {preloader: false});
    return observable;
  }

  public getFunnelLandingBlockData(campaignId, funnelId, slug = null, preloader = false): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/blocks/' + slug + '/', {}, {responseType: 'text'}, {preloader});
    return observable;
  }

  public getFunnelSettingsDomain(campaignId, funnelId): Observable<any> {
    // /api/v2/funnels/campaign/<campaign_pk>/funnel/<funnel_id>/set-subdomain/
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/set-domain/', {}, {}, {preloader: false});
    return observable;
  }

  public postFunnelSettingsDomain(campaignId, funnelId, data): Observable<any> {
    // /api/v2/funnels/campaign/<campaign_pk>/funnel/<funnel_id>/set-subdomain/
    const observable = this.api.put('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/set-domain/', {...data}, {}, {preloader: true});
    return observable;
  }

  // /api/v2/funnels/campaign/<campaign_pk>/funnel/<funnel_id>/<sequence_slug>/update-alias/<source>/
  public getFunnelLandingPageSettings(campaignId, funnelId, seqSlug, pageSlug, preloader = false): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/' + seqSlug + '/update-alias/' + pageSlug + '/', {}, {}, {preloader});
    return observable;
  }

  public postFunnelLandingPageSettings(campaignId, funnelId, seqSlug, pageSlug, data = {}): Observable<any> {
    const observable = this.api.put('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/' + seqSlug + '/update-alias/' + pageSlug + '/', data, {}, {preloader: true});
    return observable;
  }

  public getFunnelLandingPageSeoSettings(campaignId, funnelId, seqSlug, pageSlug): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/' + seqSlug + '/landing/' + pageSlug + '/settings/', {}, {}, {preloader: false, useEndpoint: 'landingsApiUrl'});
    return observable;
  }

  public setFunnelLandingPageSeoFavSettings(campaignId, funnelId, seqSlug, pageSlug, data = {}): Observable<any> {
    const observable = this.api.put('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/' + seqSlug + '/landing/' + pageSlug + '/settings/', data, {}, {preloader: true, useEndpoint: 'landingsApiUrl'});
    return observable;
  }

  //api/v2/funnels/campaign/<pk>/funnel/<int:funnel_id>/landing/<landing_slug>/
  public getFunnelLandingPageData(campaignId, funnelId, pageSlug): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/landing/' + pageSlug + '/', {}, {responseType: 'text'}, {preloader: false, useEndpoint: 'landingsApiUrl'});
    return observable;
  }

  public postFunnelLandingPageData(campaignId, funnelId, pageSlug, data): Observable<any> {
    const observable = this.api.post('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/landing/' + pageSlug + '/', data, {responseType: 'text'}, {preloader: true, useEndpoint: 'landingsApiUrl'});
    return observable;
  }

  // api/v2/funnels/campaign/42/funnel/41/opt_in_lmb_1/landing/optin_1/tracking/
  public getFunnelLandingTrackingData(campaignId, funnelId, seqSlug, pageSlug): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/' + seqSlug + '/landing/' + pageSlug + '/tracking/', {}, {}, {preloader: false});
    return observable;
  }

  public setFunnelLandingTrackingData(campaignId, funnelId, seqSlug, pageSlug, data = {}): Observable<any> {
    const observable = this.api.put('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/' + seqSlug + '/landing/' + pageSlug + '/tracking/', data, {}, {preloader: true});
    return observable;
  }

  // api/v2/funnels/campaign/42/funnel/53/set-tracking/
  public getFunnelLandingTracking(campaignId, funnelId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/set-tracking/', {}, {}, {preloader: true});
    return observable;
  }

  public patchFunnelLandingTracking(campaignId, funnelId, data): Observable<any> {
    const observable = this.api.patch('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/set-tracking/', {...data}, {}, {preloader: false});
    return observable;
  }

  //https://apifletcher.iteksas.com/api/v2/funnels/campaign/193/funnel/3/leads/export/
  public getFunnelLeadsExport(campaignId, funnelId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/leads/export/', {}, {responseType: 'text'}, {preloader: true});
    return observable;
  }

  // https://apifletcher.iteksas.com/api/v2/funnels/blocks/opt_in_form/

  // Snippet section
  public getSnippetList(): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/profiles/snippets/', {}, {}, {preloader: true});
    return observable;
  }

  public updateSnippets(data = {}): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.post('api/v2/profiles/snippets/', data, {}, {preloader: false});
    return observable;
  }

  // ====== AI ============ Zone

  // Invoke Content Generation
  public getAiGeneratePlansStatus(projectId): Observable<any> {
    const observable = this.api.get('api/v2/content-generation/' + projectId + '/generate-plans/status/', {}, {}, {preloader: false});
    return observable;
  }

  public getStartAiGeneratePlans(projectId): Observable<any> {
    const observable = this.api.get('api/v2/content-generation/start/' + projectId + '/generate-plans/', {}, {}, {preloader: true});
    return observable;
  }

  public getAiPlansWizardData(projectId): Observable<any> {
    const observable = this.api.get('api/v2/content-generation/' + projectId + '/steps/', {}, {}, {preloader: true, backendErrors: false});
    return observable;
  }

  public postAiPlansWizardStepData(projectId, pageSlug, data): Observable<any> {
    // api/v2/content-generation/193/steps/idea/update/
    const observable = this.api.post('api/v2/content-generation/' + projectId + '/steps/' + pageSlug + '/update/', {...data}, {}, {preloader: false, backendErrors: false});
    return observable;
  }

  public getAiPlansWizardStepData(projectId, pageSlug): Observable<any> {
    // api/v2/content-generation/193/steps/idea/update/
    const observable = this.api.get('api/v2/content-generation/' + projectId + '/steps/' + pageSlug + '/update/', {}, {}, {preloader: false, backendErrors: false});
    return observable;
  }

  public postAiPlansWizardStepDataAiQuery(projectId, pageSlug, data, getData): Observable<any> {
    // api/v2/content-generation/193/steps/idea/update/
    const getParams = new URLSearchParams(getData).toString();
    console.log('getParams', getParams);
    const observable = this.api.post('api/v2/content-generation/' + projectId + '/steps/' + pageSlug + '/update/?ai=true&' + getParams, {...data}, {}, {preloader: false, backendErrors: false});
    return observable;
  }

  public postAiStopGeneration(projectId): Observable<any> {
    // http://127.0.0.1:8000/api/v2/content-generation/51/stop/
    const observable = this.api.get('api/v2/content-generation/' + projectId + '/stop/', {}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public getBrainstormAiData(projectId, slug): Observable<any> {
    // http://127.0.0.1:8000/api/v2/content-generation/42/brainstorm/core_currency/prompts/
    return this.api.get('api/v2/content-generation/' + projectId + '/brainstorm/' + slug + '/prompts/', {}, {}, {preloader: false, backendErrors: true});
  }

  public postBrainstormAiData(projectId, slug, data): Observable<any> {
    // http://127.0.0.1:8000/api/v2/content-generation/42/brainstorm/core_currency/prompts/
    return this.api.post('api/v2/content-generation/' + projectId + '/brainstorm/' + slug + '/prompts/', {...data}, {}, {preloader: true, backendErrors: true});
  }

  public stopBrainstormAiData(projectId, slug): Observable<any> {
    // http://127.0.0.1:8000/api/v2/content-generation/42/brainstorm/core_currency/prompts/
    return this.api.get('api/v2/content-generation/' + projectId + '/brainstorm/' + slug + '/stop/', {}, {}, {preloader: true, backendErrors: true});
  }

  public clearBrainstormAiHistoryLast(projectId, slug): Observable<any> {
    // http://127.0.0.1:8000/api/v2/content-generation/42/brainstorm/1core_currency/forget/last/
    return this.api.get('api/v2/content-generation/' + projectId + '/brainstorm/' + slug + '/forget/last/', {}, {}, {preloader: true, backendErrors: true});
  }

  public clearBrainstormAiHistoryAll(projectId, slug): Observable<any> {
    // http://127.0.0.1:8000/api/v2/content-generation/42/brainstorm/1core_currency/forget/last/
    return this.api.get('api/v2/content-generation/' + projectId + '/brainstorm/' + slug + '/forget/all/', {}, {}, {preloader: true, backendErrors: true});
  }

  // Profile - Domains
  public getProfileDomainsList(): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/domains/', {}, {}, {preloader: true});
    return observable;
  }

  public postProfileAddDomain(data): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/domains/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public postDomainData(id, data): Observable<any> {
    const observable = this.api.patch('api/v2/account-settings/domains/' + id + '/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public postEmailData(id, data): Observable<any> {
    const observable = this.api.put('api/v2/account-settings/email/senders/' + id + '/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public deleteDomain(id): Observable<any> {
    const observable = this.api.delete('api/v2/account-settings/domains/' + id + '/', {}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public deleteEmail(id): Observable<any> {
    const observable = this.api.delete('api/v2/account-settings/email/senders/' + id + '/', {}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public verifyDomain(id, data): Observable<any> {
    if (data.is_www) {
      data = {};
      data.is_www = true;
    } else {
      data = {};
    }
    const observable = this.api.get('api/v2/account-settings/domains/' + id + '/verify/', {...data}, {}, {preloader: false, backendErrors: true});
    return observable;
  }

  public verifyEmailData(id): Observable<any> {
    // api/v2/account-settings/email/senders/<uuid>/
    const observable = this.api.get('api/v2/account-settings/email/senders/' + id + '/', {}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public verifyEmail(id): Observable<any> {
    // api/v2/account-settings/email/senders/validate/<uuid>/
    const observable = this.api.get('api/v2/account-settings/email/senders/validate/' + id + '/', {}, {}, {preloader: false, backendErrors: true});
    return observable;
  }

  public validateDomain(name): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/domains/validate/ ', { name }, {}, {preloader: false, backendErrors: false});
    return observable;
  }

  public getDomainDefaultPageData(): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/domains/default-page/', {}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public postDomainDefaultPageData(data): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/domains/default-page/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public getDomain404PageData(): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/domains/page-404/', {}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public postDomain404PageData(data): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/domains/page-404/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  // Profile - Emails
  public getProfileEmailsList(): Observable<any> {
    // api/v2/account-settings/email/senders
    const observable = this.api.get('api/v2/account-settings/email/senders/', {}, {}, {preloader: true});
    return observable;
  }

  public getProfileAgencyData(agencyId: any): Observable<any> {
    // api/v2/account-settings/email/senders
    const observable = this.api.get('api/v2/agencies/' + agencyId + '/general/', {}, {}, {preloader: true});
    return observable;
  }
  public postProfileAgencyData(agencyId: any, data: any): Observable<any> {
    // api/v2/account-settings/email/senders
    const observable = this.api.patch('api/v2/agencies/' + agencyId + '/general/', {...data}, {}, {preloader: true});
    return observable;
  }

  
  public postAskAiAsset(assetId, stageId, data): Observable<any> {
    // data - { slug, update_text }
    const observable = this.api.post('api/v2/content-generation/asset/' + assetId + '/stage-update/' + stageId + '/brainstorm/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public getAskAiAsset(assetId, stageId): Observable<any> {
    // data - { slug, update_text }
    const observable = this.api.get('api/v2/content-generation/asset/' + assetId + '/stage-update/' + stageId + '/brainstorm/', {}, {}, {preloader: false, backendErrors: false});
    return observable;
  }

  // Email Domains

  validateUserEmail(data): Observable<any> { // API
    // /api/v2/account-settings/email/senders/validate/
    const observable = this.api.post('api/v2/account-settings/email/senders/validate/', data, {preloader: true});
    return observable;
  }

  updateUserEmail(uuid, data): Observable<any> { // API
    const observable = this.api.put('api/v2/account-settings/email/senders/' + uuid + '/', data, {preloader: true, backendErrors: true});
    return observable;
  }

  createUserEmail(data): Observable<any> { // API
    const observable = this.api.post('api/v2/account-settings/email/senders/', data, {preloader: true, backendErrors: true});
    return observable;
  }

  listAgencyContacts(agencyId, data: any = {}): Observable<any> { // API
    const observable = this.api.get('api/v2/agencies/' + agencyId + '/users-list/', {...data}, {}, {preloader: true});
    return observable;
  }

  getAgencyItemActions(agencyId, userId): Observable<any> { // API
    const observable = this.api.get('api/v2/agencies/' + agencyId + '/user/' + userId + '/actions/', {}, {}, {preloader: true});
    return observable;
  }

  getAgencyPerformAction(agencyId, userId, action): Observable<any> { // API
    const observable = this.api.get('api/v2/agencies/' + agencyId + '/user/' + userId + '/' + action + '/', {}, {}, {preloader: true});
    return observable;
  }

  // Funnels Website Wizard
  getFunnelWebsiteData(campaignId = 999): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/website/data/', {}, {}, {preloader: true});
    return observable;
  }

  // GET /api/v2/funnels/campaign/42/funnel/137/landing-list/
  getFunnelWebsiteList(campaignId, funnelId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/landing-list/', {}, {}, {preloader: true});
    return observable;
  }

  public addFunnelLanding(campaignId, funnelId): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/add-landing/', {}, {}, {preloader: true});
    return observable;
  }

  // :: Asset [Email] Editor
  
  // /api/v2/funnels/asset/sequence/{{assetSequenceId}}/ 
  public getSequenceAssetEmailData(assetSequenceId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/asset/sequence/' + assetSequenceId + '/', {}, {}, {preloader: false});
    return observable;
  }

  // PATCH /api/v2/funnels/asset/sequence/{{assetSequenceId}}/
  public updateSequenceAssetEmailData(assetSequenceId, data): Observable<any> {
    const observable = this.api.patch('api/v2/funnels/asset/sequence/' + assetSequenceId + '/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  // PUT /api/v2/funnels/asset/sequence/email/{{assetSequenceId}}/
  public addSequenceEmail(assetSequenceId, data): Observable<any> {
    const observable = this.api.put('api/v2/funnels/asset/sequence/email/' + assetSequenceId + '/', {...data}, {}, {preloader: true});
    return observable;
  }

  // GET /api/v2/funnels/asset/sequence/email/{{assetSequenceId}}/{{emailRecordId}}/
  public getSequenceEmailRecord(assetSequenceId, emailRecordId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/asset/sequence/email/' + assetSequenceId + '/' + emailRecordId + '/', {}, {}, {preloader: false});
    return observable;
  }

  // PATCH /api/v2/funnels/asset/sequence/email/{{assetSequenceId}}/{{emailRecordId}}/ - редагування subject, body імейла. 
  public updateSequenceEmailRecord(assetSequenceId, emailRecordId, data): Observable<any> {
    const observable = this.api.patch('api/v2/funnels/asset/sequence/email/' + assetSequenceId + '/' + emailRecordId + '/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  // DELETE /api/v2/funnels/asset/sequence/email/{{assetSequenceId}}/{{emailRecordId}}/ - видалення імейла зі списку records.
  public deleteSequenceEmailRecord(assetSequenceId, emailRecordId): Observable<any> {
    const observable = this.api.delete('api/v2/funnels/asset/sequence/email/' + assetSequenceId + '/' + emailRecordId + '/', {}, {}, {preloader: true});
    return observable;
  }

  // Фунціонал який дозволяє добавляти імейли з іншого OriginAssetSequence
  // GET /api/v2/funnels/asset/sequence/extra/{{assetSequenceId}}/ - список asset sequence з якого можна добавити імейли до {{assetSequenceId}}
  public getSequenceEmailImportList(assetSequenceId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/asset/sequence/extra/' + assetSequenceId + '/', {}, {}, {preloader: true});
    return observable;
  }

  // PUT /api/v2/funnels/asset/sequence/extra/{{assetSequenceId}}/ - ендпоінт для додавання групи імейлів з іншого OriginAssetSequence до існуючого {{assetSequenceId}}
  public addSequenceEmailImportList(assetSequenceId, data): Observable<any> {
    const observable = this.api.put('api/v2/funnels/asset/sequence/extra/' + assetSequenceId + '/', {...data}, {}, {preloader: true});
    return observable;
  }

  // [Sequence] Відправка тестового email 
  // POST /api/v2/funnels/asset/sequence/email/{{assetSequenceId}}/{{emailRecordId}}/ /test/ - ендпоінт для відправки тестового мейла, приклад боді:
  public sendSequenceEmailTest(assetSequenceId, emailRecordId, data): Observable<any> {
    const observable = this.api.post('api/v2/funnels/asset/sequence/email/' + assetSequenceId + '/' + emailRecordId + '/test/', {...data}, {}, {preloader: true});
    return observable;
  }

  // :: *STRIPE* ::
  public getStripePaymentIntent(amount: any): Observable<any> {
    const observable = this.api.post('api/v1/billing/stripe/add-card/', {}, {}, {preloader: true});
    return observable;
  }

  public getStripeSessionStatus(clisentSecret: any): Observable<any> {
    const observable = this.api.get('api/v1/billing/stripe/session-status/', {clisentSecret}, {}, {preloader: true});
    return observable;
  }

  // :: Funnesl [ Lunch / Stop ] funnel
  // GET /api/v2/funnels/campaign/<campaign_id>/funnel/<funnel_id>/launch/
  public getFunnelLaunchStatus(campaignId, funnelId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/launch/', {}, {}, {preloader: false});
    return observable;
  }
  // attempt lunch
  // PUT /api/v2/funnels/campaign/<campaign_id>/funnel/<funnel_id>/launch/ - ендпоінт для фактичного тригеру launch funnel’у, якщо required умови виконані, то у відповідь буде 200_ОК. Якщо була спроба запуску без виконаних умов з required списку, то у відповідь прилетить 412_PRECONDITION_FAILED.
  public putFunnelLaunch(campaignId, funnelId, data = {}): Observable<any> {
    const observable = this.api.put('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/launch/', {...data}, {}, {preloader: true});
    return observable;
  }

  // attempth stop
  // DELETE /api/v2/funnels/campaign/<campaign_id>/funnel/<funnel_id>/launch/ - ендпоінт для зупинки фанелу. У відповідь буде HTTP_204_NO_CONTENT. Статус фанела стає STOPPED
  public deleteFunnelLaunch(campaignId, funnelId): Observable<any> {
    const observable = this.api.delete('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/launch/', {}, {}, {preloader: true});
    return observable;
  }

  public getFunnelDataBySlug(slug, campaignId = 999): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + slug + '/data/', {}, {}, {preloader: true});
    return observable;
  }

  // add page to funnel from map
  // GET /api/v2/funnels/campaign/42/funnel/137/add-landing/?seq_slug=virtual_cluster
  public getFunnelAddLanding(campaignId, funnelId, seqSlug): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/add-landing/', {seq_slug: seqSlug}, {}, {preloader: true});
    return observable;
  }

  // list pages (landings)
  // /api/v2/funnels/campaign/42/funnel/137/landing-list/
  public getFunnelLandingList(campaignId, funnelId, options): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/landing-list/', {}, {}, {preloader: true, ...options});
    return observable;
  }

  // reorder (update order) pages (landings)
  // POST: /api/v2/funnels/campaign/42/funnel/137/landing-list/
  public postFunnelLandingList(campaignId, funnelId, data): Observable<any> {
    const observable = this.api.post('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/landing-list/', data, {}, {preloader: true});
    return observable;
  }

  // deleting landings (landings)
  /*
  GET /api/v2/funnels/campaign/42/funnel/137/virtual_cluster/landing/custom_6/delete/
  42 - campaign
  137 - funnel
  virtual_cluster - sequence_slug
  custom_6 - landing_slug
  */
  public getFunnelLandingDelete(campaignId, funnelId, seqSlug, landingSlug): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/' + seqSlug + '/landing/' + landingSlug + '/delete/', {}, {}, {preloader: true});
    return observable;
  }

/*
@Ivan Khranovskii
 POST: https://dev.api.growthworks.io/api/v2/funnels/campaign/1035/funnel/2359/delete-cluster/
{“seq_id”: 12084} (edited) 
modern
*/
  public postFunnelDeleteCluster(campaignId, funnelId, data, options = {}): Observable<any> {
    const observable = this.api.post('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/delete-cluster/', data, {}, {preloader: true, ...options});
    return observable;
  }

  // Stripe
  // getStripeAddCardIntent
  // prepare card setup - /api/billing/stripe/setup-card/
  public getStripeAddCardIntent(): Observable<any> {
    const observable = this.api.get('api/v1/billing/stripe/setup-card/', {}, {}, {preloader: false});
    return observable;
  }

  // getStripeAddCardStatus
  // get /api/billing/stripe/setup-card-status/ 
  public getStripeAddCardStatus(): Observable<any> {
    const observable = this.api.get('api/v1/billing/stripe/setup-card-status/', {}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public getStripeRetryCharge(): Observable<any> {
    // api/billing/stripe/pay-now/
    const observable = this.api.get('api/v1/billing/stripe/pay-now/', {}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  public putImportContactsFromAllFunnels(assetSequenceId): Observable<any> {
    // PUT /api/v2/funnels/asset/sequence/import-contacts/{{assetSequenceId}}/ - ендпоінт для імпорту лідів з іншого фанелу. Робить теж саме, що і при launch funnel.
    const observable = this.api.put('api/v2/funnels/asset/sequence/import-contacts/' + assetSequenceId + '/', {}, {}, {preloader: true});
    return observable;
  }

  /* Funnels Clusters */
  /* https://dev.api.growthworks.io/api/v2/funnels/campaign/1035/funnel/2321/add-cluster/
  1035 - id кампанії
  2322 - id фанела
  на GET - список aa pages і optins, пара з яких може сформувати кластер
  */

  public getFunnelAddCluster(campaignId, funnelId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/add-cluster/', {}, {}, {preloader: false});
    return observable;
  }

  /* POST https://dev.api.growthworks.io/api/v2/funnels/campaign/1035/funnel/2321/add-cluster/ */
  public postFunnelAddCluster(campaignId, funnelId, data, options): Observable<any> {
    const observable = this.api.post('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/add-cluster/', {...data}, {}, {preloader: true, ...options});
    return observable;
  }

  // Add Sender To Funnel
  // GET /api/v2/funnels/campaign/{{campaignId}}/funnel/{{funnelId}}/sender/ - список сендерів користувача, зі статусом is_valid: true.
  public getFunnelSenderList(campaignId, funnelId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/sender/', {}, {}, {preloader: false});
    return observable;
  }
  // PATCH /api/v2/funnels/campaign/{{campaignId}}/funnel/{{funnelId}}/sender/{{senderId}}/ - метод для заміни сендера у фанела
  public patchFunnelSender(campaignId, funnelId, senderId, data): Observable<any> {
    const observable = this.api.patch('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/sender/' + senderId + '/', {...data}, {}, {preloader: true});
    return observable;
  }

  // SendGrid
  // /api/v2/account-settings/email/custom/sendgrid/ - При заході на сторінку з налаштуванням відправників необхідно спочатку робити запит на ендпоінт 
  /*
  "custom_sendgrid": false, // стутус використання кастомного sendgrid аккаунта
  "internal_sender": true, // статус використання sendgrid аккаунта

  це буде відповідь для всіх користувачів за замовчуванням, відповідно до цієї відповіді треба показувати вибраним таб 
  */  
  public getSendGridData(): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/email/custom/sendgrid/', {}, {}, {preloader: false});
    return observable;
  }

  /* POST /api/v2/account-settings/email/custom/sendgrid/ - ендпоінт для додавання власного апі ключа користувача. Наприклад:
  {
      "api_key": "SG.fxoGTkr1RGK****"
  } code
  Якщо ключ не валідний прилетить 400.
  */
  public postSendGridData(data): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/email/custom/sendgrid/', {...data}, {}, 
    {preloader: true, backendErrors: false, messageSuccess: 'SendGrid API Key has been added successfully.', messageError: 'SendGrid API Key has not been added.'});
    return observable;
  }

  /*
  PUT /api/v2/account-settings/email/custom/sendgrid/ - ендпоінт для редагування вже доданого апі ключа користувача. Приклад пейлоуда і респонса такі самі як при додаванні ключа вище. 
  */
  public putSendGridData(data): Observable<any> {
    const observable = this.api.put('api/v2/account-settings/email/custom/sendgrid/', {...data}, {}, 
    {preloader: true, backendErrors: false, messageSuccess: 'SendGrid API Key has been updated successfully.', messageError: 'SendGrid API Key has not been updated.'});
    return observable;
  }

  /*
  POST /api/v2/account-settings/email/custom/sender/ - створення, кастомного сендера під власний апі ключ користувача, всі поля required:
  */
  public postSendGridSender(data): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/email/custom/sender/', {...data}, {}, 
    {preloader: true, backendErrors: false, messageSuccess: 'SendGrid Sender has been added successfully.', messageError: 'SendGrid Sender has not been added.'});
    return observable;
  }

  /*
  PATCH /api/v2/account-settings/email/custom/sendgrid/ - світчер для перемикання між внутрішнім growthworks і кастомним sendgrid аккаунт.
  {
      "use_internal": true, 
      "use_sendgrid": false
  }
  */
  public patchSendGridSwitcher(data): Observable<any> {
    const observable = this.api.patch('api/v2/account-settings/email/custom/sendgrid/', {...data}, {}, 
    {preloader: true, backendErrors: false, messageSuccess: 'Email provider was successfully changed', messageError: 'Failed to switch Sender'});
    return observable;
  }

  /*
  updateSendGridUserEmail(uuid, data): Observable<any> { // API
    // TODO
  }*/
  createSendGridUserEmail(data): Observable<any> { // API
    return this.postSendGridSender(data);
  }

  // New Billing Section
  public getBillingData(): Observable<any> {
    const observable = this.api.get('api/v1/billing/product-plans/', {}, {}, {preloader: true});
    return observable;
  }

  /*
  Також для trial плану можна зробити cancel subscription:
  GET: /api/v1/billing/cancel-trial/
  */
  public getBillingCancelTrial(): Observable<any> {
    const observable = this.api.get('api/v1/billing/cancel-trial/', {}, {}, {preloader: true});
    return observable;
  }

  // Asset Impover Contacts V2, Now / Or Delay Schedule Offset Interface

  /* 
  GET /api/v2/funnels/asset/sequence/import-contacts/{{assetSequenceId}}/ - отримання деталів по імпорту, наприклад:
  */
  public getImportContactsV2Details(assetSequenceId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/asset/sequence/import-contacts/' + assetSequenceId + '/', {}, {}, {preloader: true});
    return observable;
  }


  /*
  POST /api/v2/funnels/asset/sequence/import-contacts/{{assetSequenceId}}/ -  створення імпорту вибраних контактів, наприклади:
  */
  public postImportContactsV2(assetSequenceId, data): Observable<any> {
    const observable = this.api.post('api/v2/funnels/asset/sequence/import-contacts/' + assetSequenceId + '/', {...data}, {}, {preloader: true});
    return observable;
  }

  /*
  PUT /api/v2/funnels/asset/sequence/import-contacts/{{assetSequenceId}}/ -  апдейт запланованого імпорту вибраних контактів.
  */
  public putImportContactsV2(assetSequenceId, data): Observable<any> {
    const observable = this.api.put('api/v2/funnels/asset/sequence/import-contacts/' + assetSequenceId + '/', {...data}, {}, {preloader: true});
    return observable;
  }

  /*
  DELETE /api/v2/funnels/asset/sequence/import-contacts/{{assetSequenceId}}/ -  видалення запланованого імпорту вибраних контактів.
    - Після видалення, на GET  в проперті existing, має бути null
  */
  public deleteImportContactsV2(assetSequenceId): Observable<any> {
    const observable = this.api.delete('api/v2/funnels/asset/sequence/import-contacts/' + assetSequenceId + '/', {}, {}, {preloader: true});
    return observable;
  }

  // Funnels :: Custom Saved User Sections Snippets ========

  // List of custom sections
  /*
  GET /api/v2/funnels/user-block/?limit=3&offset=4
  список збережених секцій
  */
  public getFunnelsUserBlockList(): Observable<any> { // TODO: доробити підтримку infinite scroll (limit/offset)
    const observable = this.api.get('api/v2/funnels/user-block/', {limit: 999, offset: 0}, {}, {preloader: true});
    return observable;
  }

  /*GET /api/v2/funnels/user-block/<user_block_id>/document/
отримання контент / html збереженої секції*/
  public getFunnelsUserBlockDocument(userBlockId): Observable<any> {
    const observable = this.api.get('api/v2/funnels/user-block/' + userBlockId + '/document/', {}, {responseType: 'text'}, {preloader: true});
    return observable;
  }

  // Send Custom User Section to Backend
  /*
  POST /api/v2/funnels/user-block/funnel/<funnel_id>/?name=some%20name%20with%20spaces
  створення / збереження секції
  funnel_id = path param is required
  name = query param is required
  content-type: application/json, text/plain, text/html - любий варіант підходить
  */
  public postFunnelsUserBlock(funnelId, name, body): Observable<any> {
    const observable = this.api.post('api/v2/funnels/user-block/funnel/' + funnelId + '/?name=' + name, body, {}, {preloader: true, messageSuccess: 'Your Section was succesfully saved'});
    return observable;
  }

  // BrainstormingxFunnels Rework
  // Brainstorm Funnel Content
  // Всі prompts і опції по brainstorm запитам лежать в моделі AIFlow із slug="landing_content" (Title "Landing sections AI")

  // GET /api/v2/funnels/funnel/3662/brainstorm/categories/
  /*  - ai_permissions - все те ж, що і на brainstorm
      - "slug" в категоріях повинен співпадати з тими, що прописані у полях "prompts" та
      "variables" відповідного AIFlow, повинен відрізнятись від змінних з планів
      - message i desc можна змінювати
      */
     // campaignId
  public getBrainstormCategories(campaignId): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/campaign/' + campaignId + '/brainstorm/categories/', {}, {}, 
      {preloader: false, backendErrors: false, messageError: 'Failed to get BrainstormAI Categories'});
    return observable;
  }

  // Доступні промпти для конкретної категорії можна за 
  // GET /api/v2/funnels/funnel/3662/brainstorm/headlines/prompts/
  public getBrainstormCategoryPrompts(campaignId, categorySlug): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/campaign/' + campaignId + '/brainstorm/' + categorySlug + '/prompts/', {}, {},
      {preloader: false, backendErrors: false, messageError: 'Failed to get BrainstormAI Prompts'});
    return observable;
  }

  /*щоб обрати підготований промпт, треба надіслати 
  POST /api/v2/funnels/funnel/3662/brainstorm/headlines/prompts/
  {"prompt_slug": "brainstorm"}
  в результаті буде "status": 0, треба перепитувати GET, поки не буде "status": 1
  тоді будуть результати від AI

  для кастомного запиту треба надіслати запит {"custom": "user custom prompt"}
  Щоб модифікувати результат, наприклад, зробити коротшим, треба передати той самий
  POST /api/v2/funnels/funnel/3662/brainstorm/headlines/prompts/
  {"prompt_slug": "shorter"},
  тоді буде взяти попередній контекст і зроблено запит з поля "prompts" ключа
  "headlines_shorter_cx"
  Після закриття попапа необхідно стерти контекст і попередні результати.
  */
  public postBrainstormCategoryPrompts(campaignId, categorySlug, data): Observable<any> {
    const observable = this.api.post('api/v2/growthworks/campaign/' + campaignId + '/brainstorm/' + categorySlug + '/prompts/', data, {}, 
      {preloader: false, backendErrors: false, messageError: 'Communication with BrainstormAI service has failed'});
    return observable;
  }

  /* Тому необхідно зробити запит на 
  GET /api/v2/funnels/funnel/3662/brainstorm/headlines/forget/all/
  інакше на наступному GET будуть попередні результати

  Таким чином для кожної категорії в моделі AIFlow повинні бути:
  data: прописані categories-slug (відмінний від тих, що є у brainstorm ai)
  variables: прописані модифікації категорій - catchy, shorter...
  prompts ддя кожної категорії, наприклад:
  "headlines_shorter", "headlines_catchy" - для перших запитів
  "headlines_shorter_cx", "headlines_catchy_cx" -   для модифікації попереднього результату, наприклад "make results witty and funny in the style ..."
  "custom_cx_start", "custom_cx_end" для кастомного 
  */

  public getBrainstormCategoryForgetAll(campaignId): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/campaign/' + campaignId + '/brainstorm/headlines/forget/all/', {}, {}, {preloader: true});
    return observable;
  }

  // Delete Custom section from backend

  /* DELETE /api/v2/funnels/user-block/<user_block_id>/
  видалення збереженої секції */
  public deleteFunnelsUserBlock(userBlockId): Observable<any> {
    const observable = this.api.delete('api/v2/funnels/user-block/' + userBlockId + '/', {}, {preloader: true, messageSuccess: 'Your Section was succesfully deleted'});
    return observable;
  }

  // Update Custom section to backend

  /* PATCH /api/v2/funnels/user-block/<user_block_id>/?name=new%20name
  ендпоінт для редагування імені секції, нове значення можна передати в query param, або в body як буде зручно. Якщо передати обидва, в пріоритеті буде значення з query param
  {
      "name": "new name"
  } */
  public patchFunnelsUserBlock(userBlockId, name): Observable<any> {
    const observable = this.api.patch('api/v2/funnels/user-block/' + userBlockId + '/?name=' + name, {}, {preloader: true, messageSuccess: 'Your Section was succesfully updated'});
    return observable;
  }

  /* перед збереженням лендінгу фронтенд буде трігерити збереження змін у лендінгу, так само як для кнопки preview. Це потрібно для того, щоб бекенд оновив скіншот і при збережені ленгдінгу, скрішнот був актуальний.
`  POST /api/v2/funnels/saved-landing/funnel/<funnel_id>/source/{{landing_slug}}/group/{{group_slug}}/?name=some%20name%20with%20spaces
  example with some params: 
  /api/v2/funnels/saved-landing/funnel/4325/source/optin_1/group/opt-ins/?name=Margaritaside
  ендпоінт для збереження лендінгу
  funnel_id = path param is required
  landing_slug = path param is required
  group_slug = path param is required
  name = query param is required
  Request content-type: application/json, text/plain, text/html - любий варіант підходить
  Request body / payload example:
  <div> hello html world </div>
  Response example:
  {
      "id": "6647032d5ee6d2d90a8eacdf", // id
      "created": "2024-05-17T07:11:41.659254Z",
      "name": "Freeport",
      "group": "Opt-in's",
      "preview": "https://s3.us-west-1.wasabisys.com/devlandingplaceholders/228/6647032d5ee6d2d90a8eacdf.png"
  }`
   */
  // Save Landing Page to Backend
  public postFunnelsSavedLanding(funnelId, landingSlug, groupSlug, name, body): Observable<any> {
    const observable = this.api.post('api/v2/funnels/saved-landing/funnel/' + funnelId + '/source/' + landingSlug + '/group/' + groupSlug + '/?name=' + name, body, {}, {preloader: true, messageSuccess: 'Your Landing was succesfully saved'});
    return observable;
  }

  /* GET /api/v2/funnels/saved-landing/groups/
  ендпоінт отримання списку груп для збереження лендінгу
  [
      {
          "icon": "https://static.growthworks.io/...",
          "slug": "opt-ins",
          "title": "Opt-in's"
      },
      {
          "icon": "https://static.growthworks.io/...",
          "slug": "authority-amplifiers",
          "title": "Authority Amplifier's"
      },
      ...
  ] */
  // Get Saved Landing Groups
  public getFunnelsSavedLandingGroups(): Observable<any> {
    const observable = this.api.get('api/v2/funnels/saved-landing/groups/', {}, {}, {preloader: true});
    return observable;
  }

  // /api/v2/growthworks/assets-gallery/ - віддає список темплейтів, кампаній, есетів і степів паків, скріни яких можуть бути додані як картинки
  public getGrowthworksAssetsGallery(data = {}, preloader = true): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/assets-gallery/', data, {}, {preloader});
    return observable;
  }
  /* 
    GET /api/v2/funnels/saved-landing/?limit=3&offset=4
    список збережених лендінгів, на ендпоінті налаштований пагінатор типу limit / offset
    [
      {
          "id": "6647032d5ee6d2d90a8eacdf",
          "created": "2024-05-17T07:11:41.659254Z",
          "name": "Freeport",
          "group": "Opt-in's",
          "preview": "https://s3.us-west-1.wasabisys.com/devlandingplaceholders/228/6647032d5ee6d2d90a8eacdf.png"
      }
    ]
  */
  // Get Saved Landing List
  public getFunnelsSavedLandingList(): Observable<any> {
    const observable = this.api.get('api/v2/funnels/saved-landing/', {limit: 999, offset: 0}, {}, {preloader: true});
    return observable;
  }

  /*
    GET /api/v2/funnels/campaign/<campaign_id>/funnel/<funnel_id>/add-landing/?seq_slug=virtual_cluster&saved_landing=6647032d5ee6d2d90a8eacdf
    ендпоінт для додавання збереженого лендінгу на мапу фанела
    campaign_id = path param is required
    funnel_id = path param is required
    seq_slug = query param is required for funnel map
    saved_landing = query param is required, сюди треба ставити id збереженого лендінгу
  */
  // Add Saved Landing to Funnel Map
  public getFunnelsAddSavedLandingMap(campaignId, funnelId, seqSlug, savedLanding): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/add-landing/?seq_slug=' + seqSlug + '&saved_landing=' + savedLanding, {}, {}, {preloader: true});
    return observable;
  }

  /*
    DELETE /api/v2/funnels/saved-landing/<id>/
    видалення збереженого лендінгу
  */
  // Delete Saved Landing
  public deleteFunnelsSavedLanding(id): Observable<any> {
    const observable = this.api.delete('api/v2/funnels/saved-landing/' + id + '/', {}, {preloader: true, messageSuccess: 'Your Landing was succesfully deleted'});
    return observable;
  }

  /*
    PATCH /api/v2/funnels/saved-landing/<id>/
    ендпоінт для редагування імені і групи збереженого лендінгу
    {
        "name": "new name",
        "group": "Opt-in's" // сюди можна передати як title гурпи так і slug групи, як буде зручно.
        
    }
  */
  // Update Saved Landing (name, group name)
  public patchFunnelsSavedLanding(id, data): Observable<any> {
    const observable = this.api.patch('api/v2/funnels/saved-landing/' + id + '/', {...data}, {}, {preloader: true, messageSuccess: 'Your Landing was succesfully updated'});
    return observable;
  }

  // Get Saved Landing Groups
  public getFunnelsSavedLandingTabsGroups(preloader = true): Observable<any> {
    const observable = this.api.get('api/v2/funnels/saved-landing/groups/', {}, {}, {preloader});
    return observable;
  }

  /* transfer to  3 arguments, third is preloader false   public addFunnelLanding(campaignId, funnelId): Observable<any> {
    // console.log('getAssetPfd', this.api);
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/add-landing/', {}, {}, {preloader: true});
    return observable;
  }*/

  public addFunnelLandingPage(campaignId, funnelId, preloader: boolean = false): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/add-landing/', {}, {}, {preloader: preloader});
    return observable;
  }
  
  /*
    GET /api/v2/funnels/campaign/<campaign_id>/funnel/<funnel_id>/add-landing/?saved_landing=6647032d5ee6d2d90a8eacdf
    ендпоінт для додавання збереженого лендінгу на вебсайт, такий 
    campaign_id = path param is required
    funnel_id = path param is required
    saved_landing = query param is required, сюди треба ставити id збереженого лендінгу
  */
  // Add Saved Landing to Funnel Page
  public getFunnelsAddSavedLandingPage(campaignId, funnelId, savedLanding, preloader: boolean = false): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/add-landing/?saved_landing=' + savedLanding, {}, {}, {preloader: preloader});
    return observable;
  }

  // FB Ads Migration
  /* Список есетів пака для перегляду 
  GET https://dev.api.growthworks.io/api/v1/growthworks/asset/556/detail/?stages=1&wowpreview=1&show_pack=1&presets=0 - це як у фанелах */
  public getGrowthworksAssetDetail(assetId, data = {}): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/detail/', data, {}, {preloader: false});
    return observable;
  }

  /*Мігрувати дані з вибраного media_assets в current:
  POST  /api/v1/growthworks/asset/529/migrate-media/lmb_facebook_ai_0_0/ 
  з body
  {"media_asset": 558}
  Якщо все ок { "status": "ok" }, інакше помилка 400 з меседжем
  */
  public postGrowthworksAssetMigrateMedia(assetId, mediaSlug, data): Observable<any> {
    const observable = this.api.post('api/v1/growthworks/asset/' + assetId + '/migrate-media/' + mediaSlug + '/', data, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  /*Взяти список есетів (паків), з яких можна мігрувати дані медіа
  GET /api/v1/growthworks/asset/529/migrate-media/lmb_facebook_ai_0_0/
  529 - id есета, в який ми хочемо імпортувати
  lmb_facebook_ai_0_0 - themes_list з якого ми будемо імпортувати (поки він один єдиний, але хай буде динамічним)
  */
  public getGrowthworksAssetMigrateMedia(assetId, themesListSlug): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/migrate-media/' + themesListSlug + '/', {}, {}, {preloader: true});
    return observable;
  }

  /*Працює лише для asset з 'facebook_ai' в template.themes_list
  GET /api/v1/growthworks/asset/31795/export-content/{images|body|headlines}/
  */
  public getGrowthworksAssetExportContent(assetId, contentType): Observable<any> {
    const observable = this.api.get('api/v1/growthworks/asset/' + assetId + '/export-content/' + contentType + '/', {}, {responseType: 'blob'}, {preloader: true});
    return observable;
  }

  // *  Caledly & HighLevel Calendar Integration ================== */

  /*GET /api/v2/account-settings/calendly/oauth/ 
ендпоінт для отримання authorization_url по якому користувач має залогінитись в свій акк calendly */
  public getCalendarOAuth(calendar, data = {}): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/' + calendar + '/oauth/', {...data}, {}, {preloader: true});
    return observable;
  }

  /* GET /api/v2/account-settings/calendly/oauth/redirect/
ендпоінт без авторизації куди буде здійснено редірект */
  public getCalendarOAuthRedirect(calendar): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/' + calendar + '/oauth/redirect/', {}, {}, {preloader: true});
    return observable;
  }

  /* POST /api/v2/account-settings/calendly/oauth/ 
отримання code для підключення аккаунта calendly, приклад пейлоуда:
{
    "code": "2ZaI97Q0p1ndw8j_fYVoOKz_xkV_szS5QmAMHoPS2R8"
}*/
  public postCalendarOAuth(calendar, data = {}): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/'+calendar+'/oauth/', {...data}, {}, {preloader: true, messageSuccess: 'Calendar Account has been connected successfully.'});
    return observable;
  }

  

  /* GET /api/v2/account-settings/calendly/account/
список підключених акаунтів Calendly*/
  public getCalendarAccount(calendar): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/'+calendar+'/account/', {}, {}, {preloader: true});
    return observable;
  }

  /* GET /api/v2/account-settings/calendly/account/<id>/
деталі одного аккаунта Calendly*/
  public getCalendarAccountDetail(calendar, id): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/'+calendar+'/account/' + id + '/', {}, {}, {preloader: true});
    return observable;
  }

  /* PATCH /api/v2/account-settings/calendly/account/<id>/
ендпоінт для перевірки і зміни статусу підключення Calendly, наприклад користувач проапгрейдив свій акк до стандартного */
  public patchCalendarAccount(calendar, id, data): Observable<any> {
    const observable = this.api.patch('api/v2/account-settings/'+calendar+'/account/' + id + '/', {...data}, {}, {preloader: true, messageSuccess: 'Calendar Account has been updated successfully.'});
    return observable;
  }

  /* DELETE /api/v2/account-settings/calendly/account/<id>/
видалення підключенного Calendly */
  public deleteCalendarAccount(calendar, id): Observable<any> {
    const observable = this.api.delete('api/v2/account-settings/'+calendar+'/account/' + id + '/', {}, {preloader: true, messageSuccess: 'Calendar Account has been deleted successfully.'});
    return observable;
  }

  // api/v2/account-settings/calendars/
  public getCalendarIntegrationsList(): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/calendars/', {}, {}, {preloader: true});
    return observable;
  }

  // ? HighLevel Calendar Integration

    /* GET /api/v2/account-settings/highlevel/account/<id>/calendar/
  id - url parameter, required
  список активних календарів у підключеному аккаунті, приклад*/
  public getHighLevelCalendarAccount( id): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/highlevel/account/' + id + '/calendar/', {}, {}, {preloader: false});
    return observable;
  }

  /* POST /api/v2/account-settings/highlevel/account/<id>/calendar/
  ендпоінт для підключення фанела до календаря, приклад body */
  public postHighLevelCalendarAccount( id, data): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/highlevel/account/' + id + '/calendar/', {...data}, {}, {preloader: true});
    return observable;
  }

  /* DELETE /api/v2/account-settings/highlevel/account/<id>/calendar/ */
  public deleteHighLevelCalendarAccount( id, data): Observable<any> {
    const observable = this.api.delete('api/v2/account-settings/highlevel/account/' + id + '/calendar/', {...data}, {preloader: false});
    return observable;
  }

  // ? Stripe Integration
  public getStripeIntegrationsData(): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/stripe/connected/', {}, {}, {preloader: true});
    return observable;
  }

  // GET: /api/v2/account-settings/stripe/disconnect/1/
  public disconnectStripeIntegration(id): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/stripe/disconnect/' + id + '/', {}, {}, {preloader: true});
    return observable;
  }

  // ? Zoom
  // Далі робимо GET запити на списки вебінарів, доступних користувачу /api/v2/account-settings/zoom/account/<account_id>/webinar/
  public getZoomWebinars(accountId): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/zoom/account/' + accountId + '/webinar/', {}, {}, {preloader: false});
    return observable;
  }
  /*При клікі на Save - нам спочатку треба відлінкувати попередній вебінар DELETE /api/v2/account-settings/zoom/account/<account_id>/webinar/ . Якщо цього не зробити - то прийде 400 помилка з беку. І якщо лінкування успішне - робимо POST /api/v2/account-settings/zoom/account/<account_id>/webinar/ зі мапінгом фанелу і при успіху показуємо тостер "Webinar added successfully. Automations are now enabled and will operate accordingly.""
  */
 // message: "Webinar added successfully. Automations are now enabled and will operate accordingly.""
  public postZoomWebinars(accountId, data = {}): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/zoom/account/' + accountId + '/webinar/', {...data}, {}, {preloader: true, messageSuccess: 'Webinar added successfully. Automations are now enabled and will operate accordingly.'});
    return observable;
  }
  public deleteZoomWebinars(accountId, data): Observable<any> {
    const observable = this.api.delete('api/v2/account-settings/zoom/account/' + accountId + '/webinar/', {...data}, {preloader: true});
    return observable;
  }

  // * Campaign Cloning ================== */

  /*
    GET /api/v2/growthworks/cloning/campaign/<campaign_id>/list/
    campaign_id - id компанії, частина url
    список з вибором елементів для клонування, на данний момент тільки есети
  */
  // Get Cloning Campaign List New variation
  public getGrowthworksCloningCampaignList(campaignId): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/cloning/campaign/' + campaignId + '/list/', {}, {}, {preloader: true});
    return observable;
  }

  /*
    POST /api/v2/growthworks/cloning/campaign/<campaign_id>/
    campaign_id - id компанії, частина url
  */

  // Post Cloning Campaign Data
  public postGrowthworksCloningCampaign(campaignId, data): Observable<any> {
    const observable = this.api.post('api/v2/growthworks/cloning/campaign/' + campaignId + '/', {...data}, {}, {preloader: true, messageSuccess: 'Cloning your campaign has started! To ensure data integrity, we do not recommend editing data in the selected objects'});
    return observable;
  }

  // * CRM Section  ================== */

  /*
  
    GET /api/v2/crm/contacts/?limit=2&offset=2&search=abcd
    список контактів, тут є пагінація типу limit offset:
    limit - query param - кількість обєктів в списку results
    offset - query param - кількість обєктів після якого потрібно показати результат 
    search - query param - параметр для пошуку, по полям: first_name, phone_number, email
  */
  // Get CRM Contacts List
  public postCRMContactsList(data): Observable<any> {
    if (data.create_days) {
      data.create_days = parseInt(data.create_days, 10);
    }
    if (data.activity_days) {
      data.activity_days = parseInt(data.activity_days, 10);
    }
    const observable = this.api.post ('api/v2/crm/contacts/', {...data}, {}, {preloader: false});
    return observable;
  }

  /*
    POST /api/v2/crm/contacts/smart/
    {
      "title": "some name",
      "filters": { // об'єкт фільтрів
          "create_days": 10,
          "groups": ["optin"]
      }
    }
  */
  // Create CRM Contacts Smart
  public createCRMContactsSmart(data): Observable<any> {
    if (data.create_days) {
      data.create_days = parseInt(data.create_days, 10);
    }
    if (data.activity_days) {
      data.activity_days = parseInt(data.activity_days, 10);
    }
    const observable = this.api.post('api/v2/crm/contacts/smart/', {...data}, {}, {preloader: false});
    return observable;
  }

  /*
    PATCH /api/v2/crm/contacts/smart/<id>/
    редагування існуючого смарт листа
    {
        "title": "NEW name",
        "filters": {
            "create_days": 1
        }
    }
  */
  // Update CRM Contacts Smart List
  public patchCRMContactsSmart(id, data): Observable<any> {
    // if (data.filters.create_days) {
    //   data.filters.create_days = parseInt(data.filters.create_days, 10);
    // }
    // if (data.filters.activity_days) {
    //   data.filters.activity_days = parseInt(data.filters.activity_days, 10);
    // }
    if (data.filters) {
      if (data.filters.create_days) {
        data.filters.create_days = parseInt(data.filters.create_days, 10);
      }
      if (data.filters.activity_days) {
        data.filters.activity_days = parseInt(data.filters.activity_days, 10);
      }
    }
    const observable = this.api.patch('api/v2/crm/contacts/smart/' + id + '/', data, {}, {preloader: false});
    return observable;
  }

  /*
    PATCH /api/v2/crm/contacts/smart/
    ендпоінт для реордирінгу смарт листів
    очікується повний список обєктів зі зміненим порядком елементі
  */
  // Update CRM Contacts Smart List Order
  public patchCRMContactsSmartOrder(data): Observable<any> {
    const observable = this.api.patch('api/v2/crm/contacts/smart/', data, {}, {preloader: false});
    return observable;
  }

  /*
    DELETE /api/v2/crm/contacts/smart/<id>/
  */
  // Delete CRM Contacts Smart List
  public deleteCRMContactsSmart(id): Observable<any> {
    const observable = this.api.delete('api/v2/crm/contacts/smart/' + id + '/', {}, {preloader: false, messageSuccess: 'Smart list was deleted successfully'});
    return observable;
  }

  /*
    GET /api/v2/crm/contacts/smart/<id>/
    деталі збереженого смарт листа
  */
  // Get CRM Contacts Smart Detail
  public getCRMContactsSmartDetail(id): Observable<any> {
    const observable = this.api.get('api/v2/crm/contacts/smart/' + id + '/', {}, {}, {preloader: true});
    return observable;
  }

  /*
    Однотипні фільтра з мультиселектом, наприклад group, tag, funnel, працюють по логічному OR. Наприклад group=optin OR group=live_optin
    GET /api/v2/crm/contacts/filter/
    ендпоінт для отримання даних по доступній фільрації, наприклад:
    {
    "groups": [
            {
                "name": "Opt-In Lead Magnet",
                "conversion": true,
                "group": "optin" // query параметр який потрібно передавати
            },
            {
                "name": "Live Event Opt-In",
                "conversion": true,
                "group": "live_optin"
            },
            {
                "name": "Booked",
                "conversion": false,
                "group": "booked"
            },
            {
                "name": "Canceled",
                "conversion": false,
                "group": "canceled"
            },
            {
                "name": "Re-Scheduled",
                "conversion": false,
                "group": "re_scheduled"
            },
            {
                "name": "Promo",
                "conversion": false,
                "group": "promo"
            },
            {
                "name": "Completed",
                "conversion": false,
                "group": "completed"
            },
            {
                "name": "Nurture",
                "conversion": false,
                "group": "nurture"
            },
            {
                "name": "Won",
                "conversion": false,
                "group": "won"
            },
            {
                "name": "Lost",
                "conversion": false,
                "group": "lost"
            },
            {
                "name": "Customer",
                "conversion": false,
                "group": "customer"
            }
        ],
        "tags": [ // список тагів
            {
                "created": "2024-09-20T12:13:53.985000Z",
                "name": "multi-lateral", // назва для відображення юзеру
                "tag": "66ed67018775534cdf646940" // query параметр який потрібно передавати
            },
            {
                "created": "2024-09-24T07:11:14.668000Z",
                "name": "lala",
                "tag": "66f26612d7091f4d4cef10a0"
            }
        ],
        "funnels": [ // список фанелів
            {
                "title": "High Ticket", // назва для відображення користувачу
                "funnel": "a977412f-c6ab-4aba-b5b9-ac5c4f2739f3" // query параметр який потрібно передавати
            },
            {
                "title": "High Ticket",
                "funnel": "659be709-a822-4b33-a9b1-f2c234219770"
            },
            ...
            
        ]
    }
  */
  // Get CRM Contacts Filter
  public getCRMContactsFilterAndSmart(): Observable<any> {
    const observable = this.api.get('api/v2/crm/contacts/filter/', {}, {}, {preloader: false});
    return observable;
  }

  /*
    GET /api/v2/crm/contacts/
список контактів, в майбутньому тут буде пагінація, поки без неї:
  */
  // Get CRM Contacts List
  // public getCRMContacts(): Observable<any> {
  //   const observable = this.api.get('api/v2/crm/contacts/', {}, {}, {preloader: true});
  //   return observable;
  // }

  // * Course Creator ================== */
  public getCourseCreatorData(campaignId, themes_list): Observable<any> {
    /*
    GET: /api/v1/growthworks/campaign/<campaign_id>/<themes_list>/pack-steps/
GET /api/v1/growthworks/campaign/42/lmbcourse/pack-steps/
HTTP 200 OK
Allow: GET, HEAD, OPTIONS
Content-Type: application/json
Vary: Accept*/
// themes_list зі списку застосувати для отримання detail для темплейту для створення есету
    const observable = this.api.get('api/v1/growthworks/campaign/'+campaignId+'/'+themes_list+'/pack-steps/', {}, {}, {preloader: true});
    return observable;
  }

  // * Universal Brainstorming AI ================== */

  /*
    ? Всі загальні prompts і опції по brainstorm запитам лежать в моделі AIFlow із slug="landing_content" (Title "Landing sections AI")
    * Також цей ai flow містить інформацію про те, які flow використовувати для тих чи інших есетів, відповідно від яких Origin Templates вони створені
    {
      "facebook_ai": {
        "title": "FB Ads",
        "template_id": [],
        "themes_list": [
          "lmb_facebook_ai_0_0",
          "lmb_facebook_ai_0_1",
          "lmb_facebook_ai_0_2",
          "lmb_facebook_ai_1_0",
          "lmb_facebook_ai_1_1",
    ...
        ]
      },
      "course_creator": {
        "title": "Course",
        "template_id": [],
        "themes_list": []
      }
    }
    ? Наприклад, для есета, що був створений за lmb_facebook_ai_1_0 буде автоматично видано категорії по AI Flow з slug="facebook_ai" і передано контекст степу 1 для промтів

    Щоб зробити запит на категорії, необхідно зробити запит
    !GET /api/v2/growthworks/campaign/1928/brainstorm/categories/?asset=30465&step_index=3

    * 1928 - id кампанії
    * 30465 - id есета, в якому запитується
    * 3 - індекс степа, з якого береться контекст. Передавати не обов'язково, а такому випадку буде обиратись нульовий степ
    * title i message для відмалювання у віджеті заголовків, заповнені в кожному окремому за групою AI Flow в базі.  Замість <group_selector_data> та <step_selector_data> треба відмалювати <select> з відповідними даними з ендпоінта, обраним  відмітити "selected": "selected"

    ? Якщо юзер насильно хоче змінити групу чи степ - передавати відповідне значення ai_flow_slug=course_creator або step_index=5. За запитом будуть надіслані нові категорії та ці значення будуть "selected"
    */

  // Get Brainstorm Categories  (campaignId, assetId, stepIndex)
  public getBrainstormCategoriesV2(campaignId, payload = {}): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/campaign/' + campaignId + '/brainstorm/categories/', {...payload}, {}, {preloader: false});
    return observable;
  }

  /* Далі працюємо із запитами до AI:
  GET: /api/v2/growthworks/campaign/1928/brainstorm/facebook_ai/headlines/prompts/

  в url:
facebook_ai - ai_flow_slug з "group_selector_data" (selected)
headlines - categories - slug - тобто 
  */

  public getBrainstormCategoryPromptsV2(campaignId, aiFlowSlug, categorySlug, getPayload = {}): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/campaign/' + campaignId + '/brainstorm/' + aiFlowSlug + '/' + categorySlug + '/prompts/', {...getPayload}, {}, {preloader: false});
    return observable;
  }

  /* Зробити запит на генерацію:
POST /api/v2/growthworks/campaign/1928/brainstorm/facebook_ai/headlines/prompts/
{"prompt_slug": slug з prompts, "step_index": вибраний індекс степу}
Далі перепитувати GET цього ж ендпоінту, поки 
  "status": 0,

коли стане
  "status": 1,
треба буде відмалювати новий набір промптів і результати
*/
  public postBrainstormCategoryPromptsV2(campaignId, aiFlowSlug, categorySlug, data, getPayload): Observable<any> {
    const getParams = new URLSearchParams(getPayload).toString();
    const observable = this.api.post('api/v2/growthworks/campaign/' + campaignId + '/brainstorm/' + aiFlowSlug + '/' + categorySlug + '/prompts/' + (getParams ? '?' + getParams : ''), data, {}, {preloader: true});
    return observable;
  }
    // Get CRM Contacts List
  // public getCRMContacts(): Observable<any> {
  //   const observable = this.api.get('api/v2/crm/contacts/', {}, {}, {preloader: false});
  //   return observable;
  // }

  // Get CRM Contacts List with Pagination
  // public getCRMContactsPagination(data): Observable<any> {
  //   const observable = this.api.get('api/v2/crm/contacts/', data, {}, {preloader: false});
  //   return observable;
  // }

  // Get CRM Contact Details
  public getCRMContactDetails(contactId): Observable<any> {
    const observable = this.api.get('api/v2/crm/contacts/' + contactId + '/', {}, {}, {preloader: false});
    return observable;
  }

  // Get CRM Contact Activities
  public getCRMContactActivities(contactId, data): Observable<any> {
    const observable = this.api.get('api/v2/crm/contacts/' + contactId + '/activities/', data, {}, {preloader: false});
    return observable;
  }

  /*GET /api/v2/crm/contacts/65491a869aec3b8aa0277843/purchases/*/
  // Get CRM Contact Purchases
  public getCRMContactPurchases(contactId): Observable<any> {
    const observable = this.api.get('api/v2/crm/contacts/' + contactId + '/purchases/', {}, {}, {preloader: false});
    return observable;
  }

  /*
    GET /api/v2/crm/contacts/<_id>/tags/
  */
  // Get CRM Contact Tags
  public getCRMContactTags(contactId): Observable<any> {
    const observable = this.api.get('api/v2/crm/contacts/' + contactId + '/tags/', {}, {}, {preloader: false});
    return observable;
  }

  /*
    POST /api/v2/crm/contacts/<_id>/tags/
  */
  // Post CRM Contact Tags
  public postCRMContactTags(contactId, data): Observable<any> {
    const observable = this.api.post('api/v2/crm/contacts/' + contactId + '/tags/', {...data}, {}, {preloader: false, messageSuccess: 'Tag added successfully', messageError: 'This tag already exists'});
    return observable;
  }

  /*
    POST /api/v2/crm/tags/batch/
    ендпоінт додавання, або створення тега, під список обраних контактів. Приклад payload:
    ids - список _id контактів до яких потрібно додати таг
    {
        "name": "Tag name",
        "ids": ["66ed549eaf87237056f230df", "66ed54a7af87237056f230e2"]
    }
  */
  // Post CRM Tags Batch
  public postCRMTagsBatch(data): Observable<any> {
    const observable = this.api.post('api/v2/crm/tags/batch/', {...data}, {}, {preloader: false, messageSuccess: 'Tag added successfully', messageError: 'This tag already exists'});
    return observable;
  }

  /*
    DELETE /api/v2/crm/tags/batch/
    ендпоінт видалення тега, під список обраних контактів. Приклад payload:
    ids - список _id контактів у яких потрібно видалити таг
    {
        "name": "Tag name",
        "ids": ["66ed549eaf87237056f230df", "66ed54a7af87237056f230e2"]
    }
  */
  // Delete CRM Tags Batch
  public deleteCRMTagsBatch(data): Observable<any> {
    const observable = this.api.delete('api/v2/crm/tags/batch/', {...data}, {preloader: false, messageSuccess: 'Tag deleted successfully'});
    return observable;
  }

  /*
  Забути результати:
GET: /api/v2/growthworks/campaign/1928/brainstorm/facebook_ai/headlines/forget/all/
*/
  public getBrainstormCategoryForgetAllV2(campaignId, aiFlowSlug, categorySlug): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/campaign/' + campaignId + '/brainstorm/' + aiFlowSlug + '/' + categorySlug + '/forget/all/', {}, {}, {preloader: false});
    return observable;
  }
  //   DELETE /api/v2/crm/contacts/<_id>/tags/<tag_id>/
  // */
  // Delete CRM Contact Tags
  public deleteCRMContactTags(contactId, tagId): Observable<any> {
    const observable = this.api.delete('api/v2/crm/contacts/' + contactId + '/tags/' + tagId + '/', {}, {preloader: false});
    return observable;
  }


  public getBrainstormCategoryForgetLastV2(campaignId, aiFlowSlug, categorySlug): Observable<any> {
    const observable = this.api.get('api/v2/growthworks/campaign/' + campaignId + '/brainstorm/' + aiFlowSlug + '/' + categorySlug + '/forget/last/', {}, {}, {preloader: false});
    return observable;
  }

  /*
    GET /api/v2/crm/tags/
    список всіх тегів користувача
    [
        {
            "created": "2024-08-28T09:20:16.085000Z", // дата створення тага
            "name": "custom tag 1", // назва тега
            "emails": [], // список привязаних імейлів до тега
            "tag_id": "66ceebd0b9ba4ec46851c7b7"
        }
    ]
  */
  // Get CRM Tags List
  public getCRMTags(): Observable<any> {
    const observable = this.api.get('api/v2/crm/tags/', {}, {}, {preloader: false});
    return observable;
  }

  /*
    PUT /api/v2/crm/contacts/<_id>/stages/<funnel_id>/
    _id - як і раніше, це айді контакта
    funnel_id - це айді фанела в якому буде змінено стейж для цього контакта
    Приклад payload:
    {"slug": "highlevel_scheduled"}
  */
  // Update CRM Contact Stage
  public putCRMContactStage(contactId, funnelId, data): Observable<any> {
    const observable = this.api.put('api/v2/crm/contacts/' + contactId + '/stages/' + funnelId + '/', {...data}, {}, {preloader: false});
    return observable;
  }

  /*
    PATCH /api/v2/crm/contacts/<_id>/
    ендпоінт для апдейта контакта, на данний момент можна змінити first_name контакта
    first_name - буде чиститись перед збереженням, а саме, прибираються пробіли по краям
    {
        "first_name": "random Name"
    }
  */
  // Update CRM Contact Name In Details
  public patchCRMContact(contactId, data): Observable<any> {
    const observable = this.api.patch('api/v2/crm/contacts/' + contactId + '/', {...data}, {}, {preloader: false});
    return observable;
  }

  // * Products ============
  // api/v2/account-settings/stripe/products/list/
  public getStripeProductsList(): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/stripe/products/list/', {}, {}, {preloader: false});
    return observable;
  }

  // create/edit product
  /*
  статуси продуктів:
  NEW = 1
  SYNC_IN_PROGRESS = 2
  SYNC_FAILED = 3
  SYNC_COMPLETE = 4
  Створення продуктів
  GET: /api/v2/account-settings/stripe/acc/7/products/add/
  7 - id акаунта (зі списка продуктів)
  на GET можна отримати всю інформацію, яка потрібна для відмалювання сторінки додавання (селектів зокрема):
  */
  public getStripeProductsAdd(accountId): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/stripe/acc/' + accountId + '/products/add/', {}, {}, {preloader: false});
    return observable;
  }

  // Post add product
  // на POST очікую інфо і по продукту, і по прайсам:
  public postStripeProductsAdd(accountId, data): Observable<any> {
    const observable = this.api.post('api/v2/account-settings/stripe/acc/' + accountId + '/products/add/', {...data}, {}, {preloader: true, backendErrors: true, messageSuccess: 'Product has been added successfully.', messageError: 'Product has not been added.'});
    return observable;
  }

  /* Update продукта і цін
  GET: /api/v2/account-settings/stripe/acc/7/products/1/update/ - інфо по продукту і цінах
  */
  public getStripeProductsUpdate(accountId, productId): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/stripe/acc/' + accountId + '/products/' + productId + '/update/', {}, {}, {preloader: false});
    return observable;
  }

  // PUT відправляти такий самий, як і створення, з відмінністю, якщо ціна існує - вказати її id, інакше створиться ще одна :)
  public putStripeProductsUpdate(accountId, productId, data): Observable<any> {
    const observable = this.api.put('api/v2/account-settings/stripe/acc/' + accountId + '/products/' + productId + '/update/', {...data}, {}, {preloader: true, backendErrors: true});
    return observable;
  }

  // GET acc/<int:acc_id>/products/<int:product_id>/delete/
  public getStripeProductsDelete(accountId, productId): Observable<any> {
    const observable = this.api.get('api/v2/account-settings/stripe/acc/' + accountId + '/products/' + productId + '/delete/', {}, {}, {preloader: true});
    return observable;
  }

  /*
    GET /api/v2/crm/contacts/?search=abc
    search - параметр для пошуку, по полям: first_name, phone_number, email.
  */
  // Get CRM Contacts List (search)
  public getCRMContactsListSearch(data): Observable<any> {
    const observable = this.api.get('api/v2/crm/contacts/', {...data}, {}, {preloader: false});
    return observable;
  }

  // Crm - Funnels Sales :: /api/v2/crm/sales/
  /*   public getFunnelLeadsList(campaignId, funnelId, q, offset): Observable<any> {
    const observable = this.api.get('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/leads/', {q, offset, limit: 10}, {}, {preloader: false});
    return observable;
  } */
  public getCRMSalesList(campaignId, funnelId, q, offset): Observable<any> {
    const observable = this.api.get('api/v2/crm/sales/', {funnel_id: funnelId, q, offset, limit: 10}, {preloader: false});
    return observable;
  }

  // PUT: /api/v2/funnels/campaign/1918/funnel/5901/26991/edit-cluster/
  public putFunnelsEditCluster(campaignId, funnelId, clusterId, data): Observable<any> {
    const observable = this.api.put('api/v2/funnels/campaign/' + campaignId + '/funnel/' + funnelId + '/' + clusterId + '/edit-cluster/', {...data}, {}, {preloader: true, backendErrors: true, messageSuccess: 'Cluster has been updated successfully.', messageError: 'Error occurred while updating cluster.'});
    return observable;
  }

}
