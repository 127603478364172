import { Injectable } from '@angular/core';

import { DataSourceService } from '@services/data-source.service';

import { ToastController, AlertController, ModalController, PopoverController } from '@ionic/angular';

import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap, tap, map, delay, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Router } from '@angular/router';

import { FormProcessorService } from '@app/-core/services/form-processor.service';

import { CreateClusterModalComponent } from '@funnels/-core/modals/create-cluster-modal/create-cluster-modal.component';

@Injectable({
  providedIn: 'root'
})
export class FunnelsCoreService {

  public activeProjectId;
  public activeFunnelId;
  public activeFunnelData; // Data
  public activePageSlug;

  public activeFunnelDataLoaded: Subject<any> = new Subject<any>();
  public mapRerenderConnections: Subject<any> = new Subject<any>();

  public retryInterval;

  public clusterSelectStates = [];

  public sections = [
    {
      title: 'Map',
      key: 'map',
      notifications: 0
    },
    {
      title: 'Stats',
      key: 'stats',
      notifications: 0
    },
    {
      title: 'Contacts',
      key: 'leads',
      notifications: 0
    },
    {
      title: 'Sales',
      key: 'sales',
      notifications: 0
    },
    {
      title: 'Settings',
      key: 'settings',
      notifications: 0
    }
  ];

  public themesList = [];

  public blockTagList;
  public activeSectionList;
  public funnelsBlockDataCache: any = [
    { slug: 'demo', body: null },
  ];

  public gridSize = 16;

  constructor(
    public dataSource: DataSourceService,
    public router: Router,
    public toastController: ToastController,
    public alertController: AlertController,
    public modalController: ModalController,
  ) { 
    this.activeFunnelDataLoaded.next(null);
    console.log('FunnelsCoreService init');

    /*
    setInterval( () => {
      console.log('Cluster DEBUG this.funnelsCore.expandedRendererExclusive', this.expandedRendererExclusive);
    }, 3000);*/

    this.themesList = [
      {
        title: 'Basic',
        description: 'Lorem ipsum dolor sit amet consectetur. Enim venenatis fringilla senectus lectus. Enim odio ipsum elit montes.',
        slug: 'basic',
        colors: [
          '#144DDE',
          '#F24338',
          '#F29138',
        ]
      },
      {
        title: 'Feminine',
        description: 'Lorem ipsum dolor sit amet consectetur. Enim venenatis fringilla senectus lectus. Enim odio ipsum elit montes.',
        slug: 'feminine',
        colors: [
          '#144DDE',
          '#F24338',
          '#F29138',
        ]
      },
    ];
  }

  setActiveProject(projectId, id) {
    
    this.activeProjectId = projectId;
    this.activeFunnelId = id;

    this.activeFunnelData = null;
    this.dataSource.getFunnelDetails(projectId, id).subscribe( res => {
      this.activeFunnelData = res;
      // Conver new asset data structure to legacy format
      if ( this.activeFunnelData && this.activeFunnelData.assets_data ) {
        // if assets_data is object
        if ( typeof this.activeFunnelData.assets_data === 'object' && !(this.activeFunnelData.assets_data instanceof Array) ) {
          const assets_data = [];
          for (const [key, value] of Object.entries(this.activeFunnelData.assets_data)) {
            // for each item in value array append cluster slug
            (value as any).forEach( item => {
              item.cluster_slug = key;
              assets_data.push(item);
            });
          }
          console.log('Converted assets_data - converting', typeof this.activeFunnelData.assets_data);
          // this.activeFunnelData.assets_list = assets_data;
        }
        console.log('Converted assets_data', this.activeFunnelData);
      }

      this.activeFunnelDataLoaded.next(res);

      if (res.slug === 'website') {
        this.sections[0].title = 'Pages';
        this.sections[0].key = 'pages';
      } else {
        this.sections[0].title = 'Map';
        this.sections[0].key = 'map';
      }

      console.log('FUNNELS :: setActiveProject', this.activeFunnelData);
    }, err => {

      console.log('FUNNELS :: Error / Wait');
      this.retryInterval = setTimeout( () => {
        this.setActiveProject(projectId, id);
      }, 2500);

    });
  }

  public expandedRendererExclusive: any = false;
  removeActiveProject() {
    
    this.activeFunnelData = null;
    this.activeFunnelId = null;
    this.activeProjectId = null;
    if ( this.retryInterval ) {
      clearInterval(this.retryInterval);
    }

    this.expandedRendererExclusive = false;

  }

  invokeShare() {
    alert('share ' + this.activeProjectId + ' ' + this.activeFunnelId);
  }

  // Map Math
  calculateBlockConnectionPoints(from, to, position: any = 'auto') {

    console.log('calculateBlockConnectionPoints', this.getObjectDimensions(from), this.getObjectDimensions(to));

    // DEFAULT :: DETECT POSITION BASED ON FROM AND TO COORDINATES
    if ( position == 'auto' ) {
      if ( this.getObjectDimensions(from).centerY > this.getObjectDimensions(to).centerY ) {
        // Top Check
        console.log('calculateBlockConnectionPoints - check TOP');
        if ( this.getObjectDimensions(from).y >= this.getObjectDimensions(to).endY ) {
          // Sure Top
          console.log('calculateBlockConnectionPoints - sure TOP');
          return { 
            from: this.getObjectDimensionsScaled(from).topCenter, 
            to: this.getObjectDimensionsScaled(to).bottomCenter, 
            curve: 1, 
            fromS: 'top' 
          };
        }
      }
  
      if ( this.getObjectDimensions(from).centerY < this.getObjectDimensions(to).centerY ) {
        // Bottom Check
        console.log('calculateBlockConnectionPoints - check BOT');
        if ( this.getObjectDimensions(from).endY <= this.getObjectDimensions(to).y ) {
          // Sure Bottom
          console.log('calculateBlockConnectionPoints - sure BOT');
          return { 
            from: this.getObjectDimensionsScaled(from).bottomCenter, 
            to: this.getObjectDimensionsScaled(to).topCenter, 
            curve: 1, 
            fromS: 'bottom' 
          };
        }
      }
  
      if ( this.getObjectDimensions(from).x >= this.getObjectDimensions(to).endX ) {
        console.log('calculateBlockConnectionPoints - sure LEFT');
        return { 
          from: this.getObjectDimensionsScaled(from).leftCenter, 
          to: this.getObjectDimensionsScaled(to).rightCenter, 
          curve: -1, 
          fromS: 'left'
         };
      } else {
        console.log('calculateBlockConnectionPoints - sure RIGHT');
        return { 
          from: this.getObjectDimensionsScaled(from).rightCenter, 
          to: this.getObjectDimensionsScaled(to).leftCenter, 
          curve: -1, 
          fromS: 'right'
        };
      }
    }

    // DEFINED POSITION (1-12) STARTING TOP LEFT GOING CLOCKWISE 3 on each side
    if ( Array.isArray(position) ) {
      const itemPositionFrom = this.getObjectDimensionsScaled(from);
      const itemPositionTo = this.getObjectDimensionsScaled(to);

      const curveture = null;
      const fromS = ( position[0] <= 3 ) ? 'top' : ( position[0] <= 6 ) ? 'right' : ( position[0] <= 9 ) ? 'bottom' : 'left';

      const getCoordinateTarget = (position) => {
        if ( position === 1 ) return 'topLeft';
        if ( position === 2 ) return 'topCenter';
        if ( position === 3 ) return 'topRight';
        if ( position === 4 ) return 'rightTop';
        if ( position === 5 ) return 'rightCenter';
        if ( position === 6 ) return 'rightBottom';
        if ( position === 7 ) return 'bottomRight';
        if ( position === 8 ) return 'bottomCenter';
        if ( position === 9 ) return 'bottomLeft';
        if ( position === 10 ) return 'leftBottom';
        if ( position === 11 ) return 'leftCenter';
        if ( position === 12 ) return 'leftTop';
      };

      return { 
        from: itemPositionFrom[getCoordinateTarget(position[0])], 
        to: itemPositionTo[getCoordinateTarget(position[1])], 
        curve: curveture, 
        fromS
      };
    }

    return null;

  }

  getObjectDimensions(item) {
    const baseShift = 32;
    const connectionShift = 6;

    const endX = item.x + item.w;
    const endY = item.y + item.h;
    const centerX = item.x + (item.w / 2);
    const centerY = item.y + (item.h / 2);

    const topCenter = { x: centerX, y: item.y - connectionShift };
    const bottomCenter = { x: centerX, y: endY + connectionShift };
    const leftCenter = { x: item.x - connectionShift, y: centerY };
    const rightCenter = { x: endX + connectionShift, y: centerY };

    const topLeft = { x: item.x + baseShift, y: item.y - connectionShift };
    const topRight = { x: endX - baseShift, y: item.y - connectionShift };

    const rightTop = { x: endX + connectionShift, y: item.y + baseShift };
    const rightBottom = { x: endX + connectionShift, y: endY - baseShift };

    const bottomRight = { x: endX - baseShift, y: endY + connectionShift  };
    const bottomLeft = { x: item.x + baseShift, y: endY + connectionShift };

    const leftBottom = { x: item.x - connectionShift, y: endY - baseShift };
    const leftTop = { x: item.x - connectionShift, y: item.y + baseShift };

    return { ...item, endX, endY, 
      centerX, centerY, 
      
      topCenter, bottomCenter, leftCenter, rightCenter,
    
      topLeft, topRight, 

      rightTop, rightBottom, 

      bottomRight, bottomLeft, 

      leftBottom, leftTop
    };
  }

  getObjectDimensionsScaled(item) {
    const clone = JSON.parse(JSON.stringify(item));

    clone.x *= this.gridSize;
    clone.y *= this.gridSize;
    clone.w *= this.gridSize;
    clone.h *= this.gridSize;

    return this.getObjectDimensions(clone);
  }

  // Asset/Page Fetch
  getAssetBySlug(slug, group = 'other') {

    {

      try{
        // Legacy Support
        let subject = this.activeFunnelData.assets_data[group];

        

        const find = subject.find( x => x.step_slug === slug );
        if ( find ) {
          if ( find.assets && find.assets.length ) {
            /*
            const subfind = find.assets.find( x => x.is_current === true );
            if ( subfind ) {
              find.selected_asset = subfind;
              find.current = subfind.id;
            } else {
              find.selected_asset = false;
              find.current = false;
            }
            */
          }

          // console.info('getAssetBySlug', slug, group, subject, find);
          // console.log('Converted assets_data - getAssetBySlug find', slug, find);
          return find;
        }
      } catch (err) {
        // New Structure
        // console.log(err);
      }

    }

    return false;
  }

  getPageBySlug(slug) {
    
    // console.log('getPageBySlug', slug);

    let res: any = false;

    for (const [key, value] of Object.entries(this.activeFunnelData.sequences_data)) {

      const find = (value as any).find( y => y.source === slug );
      if ( find ) {
        find.cluster_key = key;
        res = find;
        // console.log('getPageBySlug', slug, res);
        return res;
      }

    }

    return res;
  }

  getTagListConcated() {
    const res = [];
    
    try{
      this.blockTagList.forEach( item => {
        if ( item.subgroups.length ) {
          item.subgroups.forEach( subitem => {
            res.push({ name: item.title + ' - ' + subitem.title, value: [item.slug, subitem.slug] })
          });
        } else {
          res.push({ name: item.title, value: [item.slug]});
        }
      });
    } catch (err) {

    }

    return res;
  }

  leaveFunnelModule() {
    // console.log('leaveFunnelModule', this.activeFunnelData);
    // return;
    if ( this.activeFunnelData && this.activeFunnelData.slug === 'website' ) {
      this.router.navigate(['/app/websites']);
    } else {
      this.router.navigate(['/app/funnels']);
    }
  }

  async presentPageAddedToast() {
    const toast = await this.toastController.create({
      message: 'Congratulations! The page has successfully created. Funnel map is being rebuilded',
      duration: 2000,
      color: 'success'
    });
    toast.present();
  }

  async presentPageToWebsiteAddedToast() {
    const toast = await this.toastController.create({
      message: 'Congratulations! The page has successfully created.',
      duration: 2000,
      color: 'success'
    });
    toast.present();
  }

  async presentPageDeletedToast() {
    const toast = await this.toastController.create({
      message: 'Done! Page successfully deleted',
      duration: 2000,
      color: 'danger'
    });
    toast.present();
  }

  async presentDeletePageConfirm(item) {
    /* Cases */
    // 1. Item is not connected to any other page
    // 2. Item with child
    // 3. Child of an item 

    console.log('presentDeletePageConfirm', item);
    let header = '';
    if ( item.master && item.child ) {
      if ( item.isChild ) {
        header = 'Delete Child Page';
      } else {
        header = 'Delete Parent Page + Child';
      }
    } else {
      header = 'Delete Page';
    }

    const alert = await this.alertController.create({
      header,
      message: 'Are you sure you want to delete this page forever?',
      cssClass: 'application-v2 alert-v2 delete-page-alert',
      buttons: [
        // ok, cancel buttons
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {

            console.log('Confirm Okay');
            this.dataSource.getFunnelLandingDelete(this.activeProjectId, this.activeFunnelId, item.sequence_slug, item.landing_slug).subscribe( (res) => {
              console.log('getFunnelLandingDelete', res);
              setTimeout( () => {
                // this.ngOnInit();
                this.reload();
                this.presentPageDeletedToast();
              }, 100);
            }, async err => {
              const toast = await this.toastController.create({
                message: 'Attention! You can not delete this landing',
                duration: 4000,
                color: 'warning'
              });
              toast.present();
            });
          }
        }
      ]
    });

    await alert.present();
  }

  reload() {
    this.setActiveProject(this.activeProjectId, this.activeFunnelId);
  }

  setClusterPositionPersistant(cluster) {

    console.log('setClusterPositionPersistant', cluster, this.activeFunnelId);
    // save to .storage
    if ( cluster.data && cluster.data.type === 'stack' ) {
      this.dataSource.storage.set(this.activeFunnelId + '-page-' + cluster.data.slug, cluster.data.selectedPage);
    } else if ( cluster.data && cluster.data.type === 'cluster' ) {
      this.dataSource.storage.set(this.activeFunnelId + '-cluster-' + cluster.data.slug, cluster.data.selectedSection);
    }
    // do nothing ;)

  }

  setClusterExpanedPersistant(cluster) {
    this.expandedRendererExclusive = cluster;
    // this.expandedRendererExclusive.funnelId = this.activeFunnelId;
    console.log('Cluster DEBUG setClusterExpanedPersistant', cluster, this.activeFunnelId);
    // save to .storage
    this.dataSource.storage.set((this.activeFunnelId + '-last-known-cluster-expanded'), this.expandedRendererExclusive);
  }
  async getClusterExpanedPersistant() {
    const expanded = await this.dataSource.storage.get(this.activeFunnelId + '-last-known-cluster-expanded');
    return expanded;
  }
  removeClusterExpanedPersistant() {
    this.expandedRendererExclusive = false;
    this.dataSource.storage.remove(this.activeFunnelId + '-last-known-cluster-expanded');
  }


  async restoreClusterPositionFromStore(cluster) {
    console.log('Cluster DEBUG restoreClusterPositionFromStore', cluster, this.activeFunnelId);
    if ( cluster && cluster.data && cluster.data.type === "cluster" ) {
      const position = await this.dataSource.storage.get(this.activeFunnelId + '-cluster-' + cluster.data.slug);
      console.log('Cluster DEBUG restoreClusterPositionFromStore position', position);
      if ( position ) {
        cluster.data.selectedSection = position;
      }
    }
    if ( cluster && cluster.data && cluster.data.type === "stack" ) {
      const position = await this.dataSource.storage.get(this.activeFunnelId + '-page-' + cluster.data.slug);
      console.log('Cluster DEBUG restoreClusterPositionFromStore position', position);
      if ( position ) {
        cluster.data.selectedPage = position;
      }
    }

    // on delete verify if its possible (if still existsts)
    if ( cluster && cluster.data && cluster.data.type === "cluster" && cluster.data.selectedSection >= cluster.data.sections.length ) {
      cluster.data.selectedSection = 0;
    }
    if ( cluster && cluster.data && cluster.data.type === "stack" && cluster.data.selectedPage >= cluster.data.size ) {
      cluster.data.selectedPage = 0;
    }
  }

  // expand
  /*
  async restoreClusterExpandedFromStore(cluster) {
    console.log('Cluster DEBUG restoreClusterExpandedFromStore', cluster, this.activeFunnelId);
    if ( cluster && cluster.data && cluster.data.type === "cluster" ) {
      const expanded = await this.dataSource.storage.get(this.activeFunnelId + '-cluster-expanded');
      console.log('Cluster DEBUG restoreClusterExpandedFromStore expanded', expanded, cluster.data.cluster_slug);
      if ( expanded === cluster.data.cluster_slug ) {
        cluster.data.expanded = true;
      }
    }
    // expandedRendererExclusive
    if ( cluster && cluster.data && cluster.data.type === "cluster" && cluster.data.expanded ) {
      this.expandedRendererExclusive = cluster;
    }
  }*/


}
