// Dev Enviromental Setup
//
//
declare const require: any;

export const environment = {
  production: false,

  appUrl: 'https://dev.app.growthworks.io',
  apiUrl: 'https://dev.api.growthworks.io',
  landingsApiUrl: 'https://dev.api.growthworks.io',

  version: 2,
  chatBar: 'modern',

  serviceWorkerUpdateTimeout: 1,

  agencyBrandingRendering: false,

  releaseAgencyWebsites: true, // Silent Release for Agency/Websites

  gTagManager: "G-83KKNX5GFN",
  
  stripePkKey: "pk_test_7aIJRqimjLvvqdOhi1yYa1dk00j5Oxv2Rj",
  stripeAuthIntercept: "d3fb4b251266af9637435310ea9888980dca7e24",
};
